const contacts = [
  'persons',
  'companies',
  'departments',
  'department-roles',
  'parties_can_login',
  'company_types',
  'persons_can_login',
  'company_connected_companies',
  'company_supplier_evaluations',
  'company_agent_evaluations',
  'company_charterers_evaluations',
  'company_bank_accounts'
];

const vessels = [
  'vessels',
  'vessel_crew',
  'vessel_groups',
  'vessel_shares',
  'vessel_share_requests',
  'vessel_documents',
  'vessel-document-types',
  'vessel-document-categories',
  'vessel_report_comparison',
  'vessel_document_reminders',
  'vessel_perfomance_reports',
  'document_manager_vessels_per_document',
  'document_manager_vessels_list',
  'vessel_ams_dashboard',
  'vessel_voyage_forecast',
  'vessel_formula_logs'
];

const crew = [
  'crew',
  'crew_profile_documents',
  'crew_profile_training',
  'crew_profile_medical',
  'crew_profile_skills_cargo_handled',
  'crew_planning',
  'crew_planning_history',
  'crew_seamen_contracts',
  'crew_seaman_profile_contracts',
  'crew_ranks',
  'crew-evaluation-templates',
  'crew_evaluation_obligation_templates',
  'crew_evaluation_obligations',
  'crew_evaluation_criteria',
  'crew_evaluation_criteria_categories',
  'crew_ticketing_types',
  'crew_holidays',
  'crew_document_types',
  'crew_matrices',
  'crew_can_login',
  'crew_wages',
  'crew_skill_types',
  'crew_termination_reasons',
  'crew-training-types',
  'document_manager_crew',
  'document_manager_crew_documents',
  'drills',
  'training_types',
  'wages',
  'crew_list',
  'ticketing_events',
  'ticketing_crew',
  'ticketing_tickets',
  'crew_payroll',
  'crew_seamen_contracts'
];

const voyages = [
  'voyages',
  'estimations',
  'market-indexes',
  'bunker-prices',
  'ports',
  'captain_reports',
  'captain_reports_running_hours',
  'captain_report_templates',
  'captain_report_port_document_overview',
  'charter_parties',
  'charter_party_hires',
  'bunkering_prices',
  'port_da_items',
  'port_da_cases',
  'freight_hire_collection_items',
  'freight_hire_invoices',
  'port_agents'
];

const jobs = [
  'jobs_list',
  'jobs_flag_extensions_dispensations_list',
  'jobs_orca_jobs_list',
  'jobs_detailed',
  'jobs_templates',
  'jobs_statuses',
  'jobs_restricted',
  'jobs_triggers'
];

const pms = [
  'pms_jobs_list',
  'pms_jobs_per_system',
  'pms_job_prototypes',
  'pms_spare_parts',
  'pms_system_attributes',
  'pms_vessel_systems',
  'pms_vessel_system_assignments',
  'pms_job_types',
  'pms_system_groups'
];

const reports = [
  'reports_crew',
  'reports_past_promotions',
  'reports_promotions',
  'reports_voyage',
  'reports_lubricants_supply_monitoring',
  'reports_lubricants_remaining_days_estimator',
  'reports_fresh_water_generator_performance',
  'reports_fresh_water_generator_performance_fleet',
  'reports_stern_tube_report',
  'reports_cii_reference_lines',
  'reports_cii_fleet_running_cps',
  'reports_cii_fleet_for_one_year',
  'reports_cii_fleet_running_legs',
  'reports_port_statement_bunkering_and_bunker_survey',
  'reports_captain_reports_bunkers',
  'reports_mrv',
  'reports_imo_dcs',
  'reports_change_logs',
  'reports_evaluations',
  'reports_onboard_nationalities',
  'reports_on_signer_statistics',
  'reports_on_signer_evaluation_statistics',
  'reports_overlapping_days',
  'reports_training_needs',
  'reports_trainings',
  'reports_vaccinations',
  'reports_not_for_reemployment',
  'reports_newly_hired',
  'reports_retention_rate_intertanko',
  'reports_retention_rate',
  'reports_pms',
  'reports_event',
  'reports_overdue_jobs',
  'reports_job_statistics',
  'reports_crew_wages',
  'reports_main_engine_piston_and_cylinder_liner_wear_down',
  'reports_purchasing_budgets',
  'reports_purchasing_items_ordered_on_board',
  'reports_purchasing_requested_but_not_delivered',
  'reports_port_cagoes',
  'reports_weather_analysis',
  'reports_lub_oil_analysis',
  'reports_attendance_events',
  'reports_off_hire_totals',
  'reports_class_related_components',
  'reports_tanks_condition_summary',
  'reports_running_hours_summary',
  'reports_megger_and_vibration_report_for_motors',
  'reports_critical_equipment_performance_test_and_data',
  'reports_fixtures',
  'reports_vetting_overview',
  'reports_vessel_overall_condition',
  'reports_findings',
  'reports_vessel_cii_profile',
  'reports_vessel_cii_profile_laden',
  'reports_vessel_cii_profile_ballast',
  'reports_crew_medical_cases',
  'reports_underwater_services',
  'reports_underwater_services_fleet',
  'reports_daily_fleet_performance_report',
  'reports_crew_medical_statistics',
  'reports_crew_wages_types',
  'reports_crew_wages_ranks',
  'reports_crew_wages_vessel',
  'reports_crew_promotions_contracts_seamen',
  'reports_ticketing_ticketing_type',
  'reports_ticketing_rank',
  'reports_ticketing_nationality',
  'reports_crew_medical_case_categories',
  'reports_port_documents',
  'reports_root_cause_analysis_statistics',
  'reports_budgeting',
  'reports_agents_appointed',
  'reports_stoppages',
  'reports_audits_and_inspections_statistics',
  'reports_risk_assessment_statistics',
  'reports_budgeting',
  'reports_ports_and_cargoes_statistics'
];

const purchasing = [
  'purchasing_categories',
  'purchasing_packing',
  'purchasing_unit_types',
  'purchasing_requisitions',
  'purchasing_requisitions_ev',
  'purchasing_requisitions_approvals',
  'purchasing_requisition_templates',
  'purchasing_pricelists',
  'purchasing_budgeted_quantity',
  'purchasing_pricelist_items',
  'purchasing_pricelist_price_zones',
  'purchasing_library_setup_list',
  'purchasing_forwarding_items',
  'purchasing_forwarding_orders',
  'purchasing_forwarding_cases'
];

const forms = [
  'form_submissions',
  'form_statuses',
  'form_planning_settings',
  'form_planning',
  'form_table_reports',
  'form_fields',
  'form_obligations',
  'form_obligation_settings',
  'digital_forms',
  'upload_forms'
];

const manuals = ['manuals', 'manuals_versioning'];

const projects = ['projects'];

const reviewProcesses = ['review_process_list', 'review_process_templates'];

const hsqe = ['root_causes', 'root_cause_categories'];

const vetting = ['vetting_setup'];

const riskAssessment = ['risk_assessment_categories', 'risk_assessment_operations'];

const events = [
  'events',
  'event_statuses',
  'event_accounts_categories',
  'events_audits_types',
  'events_audits_inspections',
  'events_audits_reasons',
  'events_audits_evaluations',
  'event_templates',
  'event_underwater_service_types',
  'events_accounts',
  'event_off_hire',
  'events_linked_purchase_cases',
  'events_underwater_services',
  'events_calendar',
  'events_lub_oil_analysis_settings',
  'events_lub_oil_analysis_assignments',
  'claims_expenses',
  'medical_cases_types',
  'medical_cases_categories',
  'event_form_obligations',
  'event_form_submissions',
  'event_audit_findings'
];

const findings = ['finding_subcategories', 'finding_tags', 'finding_categories'];

const mga = [
  'mga_action_types',
  'mga_items',
  'mga_actions',
  'mga_accounts',
  'mga_overview',
  'mga_inventory_items_overview',
  'mga_inventory_items_additions',
  'mga_inventory_items_removals',
  'mga_periods_table'
];

const accounting = [
  'accounting_invoices',
  'accounting_invoices_tickets',
  'accounting_invoices_requisition_suppliers',
  'accounting_invoices_da_agents',
  'accounting_invoices_da_agent_items',
  'accounting_budget',
  'accounting_companies_settings',
  'accounting_invoices_events_accounts',
  'accounting_invoices_events_crew_medical_cases_expenses',
  'accounting_budget_scenarios',
  'accounting_ledger_cards',
  'budgeting_report_setup',
  'accounting_account_types',
  'accounting_contracts',
  'accounting_contracts_payments'
];

const inventory = ['inventory_spare_parts', 'inventory_items'];

const development = ['onboard_syncs'];

const it = ['it_dashboard'];

const performance = [
  'noon_reports_technical_overview',
  'system_tags',
  'generated_tags',
  'default_sources',
  'tag_mappings',
  'ams_configuration'
];

const beacon = ['beacon_posts'];

const newsAndAnnouncements = ['news_announcements'];

const generic = ['case_tags', 'notification_preferences_administration'];

const keys = [
  ...contacts,
  ...vessels,
  ...crew,
  ...voyages,
  ...jobs,
  ...pms,
  ...reports,
  ...purchasing,
  ...reviewProcesses,
  ...hsqe,
  ...riskAssessment,
  ...events,
  ...forms,
  ...manuals,
  ...mga,
  ...accounting,
  ...development,
  ...it,
  ...accounting,
  ...projects,
  ...inventory,
  ...vetting,
  ...performance,
  ...beacon,
  ...newsAndAnnouncements,
  ...findings,
  ...generic
];

export default keys;
