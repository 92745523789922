import { deleteRequest, post, put } from '@/api';
import { ReviewProcess, ReviewProcessAction } from '@/common/types/review-process';
import { TriggeredSystemTask } from '@/types/system-tasks';

export type ReplaceReviewProcessParams = {
  id: number;
};

export const replaceReviewProcess = async (params: ReplaceReviewProcessParams) => {
  const { id } = params;
  return (await put(`/review-processes/${id}/replace`)).data;
};

export const approveReviewProcess = async (params: { id: number }) => {
  return (await put(`/review-processes/${params.id}/approve`)).data;
};

export const rejectReviewProcess = async (params: { id: number }) => {
  return (await put(`/review-processes/${params.id}/reject`)).data;
};

export const bulkApproveReviewProcessSteps = async (params: {
  review_process_ids: number[];
  review_process_action: ReviewProcessAction;
}) => {
  const response = await put<TriggeredSystemTask>(`/review-processes/bulk-approve`, params);

  return response.data;
};

export type SetupReviewProcessStepParams = {
  is_required?: boolean;
  substeps: { department_ids: number[]; department_role_ids: number[]; id?: number }[];
};

export const addReviewProcessStep = async (
  params: SetupReviewProcessStepParams & { id: number | string }
) => {
  const { id, ...rest } = params;

  return (await post<ReviewProcess>(`/review-processes/${id}/review-process-steps`, rest)).data;
};

export const editReviewProcessStep = async (
  params: SetupReviewProcessStepParams & { step_id: number }
) => {
  const { step_id, ...rest } = params;

  return (await put<ReviewProcess>(`/review-process-steps/${step_id}`, rest)).data;
};

export const deleteReviewProcessStep = async (params: { step_id: number }) => {
  const { step_id } = params;

  return (await deleteRequest<ReviewProcess>(`/review-process-steps/${step_id}`)).data;
};
