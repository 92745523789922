import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import TextareaAutosize from 'react-autosize-textarea';
import Textarea from '@/ts-common/components/form/inputs/Textarea';
import useAutoSaveInputValue from '@/ts-common/utils/hooks/useAutoSaveInputValue';

const DeprecatedTextarea = ({ autoResize, ...rest }) => {
  if (autoResize) return <TextareaAutoResize {...rest} />;

  return <Textarea {...rest} />;
};

const TextareaAutoResize = ({
  error = '',
  className = '',
  name = undefined,
  value,
  onChange,
  label = '',
  placeholder,
  rows,
  maxLength = undefined,
  readOnly = false,
  disabled,
  invisible = false,
  dataCy = undefined,
  onAutoSave = () => new Promise(resolve => resolve(null)),
  ...rest
}) => {
  const { onAutoSave: autoSaveValue } = useAutoSaveInputValue(onAutoSave);

  return (
    <FormGroupWrap
      className={`${invisible ? 'invisible-input' : ''} ${className}`}
      label={label}
      error={error}
      dataCy={dataCy}
    >
      <div className="position-relative flex-fill">
        <TextareaAutosize
          type={'textarea'}
          value={value || ''}
          name={name}
          onChange={e => {
            if (onChange) onChange(e);
            autoSaveValue(e.target.value);
          }}
          placeholder={placeholder ? placeholder : ''}
          rows={rows ? rows : 3}
          maxLength={maxLength}
          readOnly={readOnly}
          disabled={disabled || false}
          className={`form-control form-field gray-scrollbar ${disabled ? 'disabled' : ''}`}
          {...rest}
        />
      </div>
    </FormGroupWrap>
  );
};

export default DeprecatedTextarea;
