import { ChangeEventHandler, FC, ReactNode, useState } from 'react';
import { Input as InputField, Button } from 'reactstrap';
import { InputProps as InputFieldProps, InputType } from 'reactstrap/types/lib/Input';

import SvgRender from '@/ts-common/components/general/SvgRender';
import eyeSvg from '@/ts-common/assets/svg/common/eye.svg';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import PasswordValidation from '@/ts-common/components/general/password/PasswordValidation';
import FormFieldPreviewer from '@/ts-common/components/form/helpers/FormFieldPreviewer';
import useAutoSaveInputValue from '@/ts-common/utils/hooks/useAutoSaveInputValue';

export interface InputProps extends Omit<InputFieldProps, 'size'> {
  error?: string | null;
  className?: string;
  inputClassName?: string;
  viewOnly?: boolean;
  label?: ReactNode | string;
  invisible?: boolean;
  size?: 'sm' | 'lg';
  showValidation?: boolean;
  dataCy?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onAutoSave?: (value: string) => Promise<unknown>;
}

const Input: FC<InputProps> = ({
  error = '',
  className = '',
  inputClassName = '',
  type = 'text',
  viewOnly,
  label,
  value,
  invisible,
  placeholder,
  onBlur,
  maxLength,
  disabled,
  size = 'sm',
  showValidation,
  dataCy,
  onChange,
  onAutoSave = () => new Promise(resolve => resolve(null)),
  ...rest
}) => {
  const [inputType, setInputType] = useState<InputType>(type);
  const { onAutoSave: autoSaveValue } = useAutoSaveInputValue(onAutoSave);

  if (viewOnly)
    return <FormFieldPreviewer className={className} label={label} value={value} type="string" />;

  return (
    <FormGroupWrap
      className={`${invisible ? 'invisible-input' : ''} ${className} position-relative`}
      label={label}
      error={error}
    >
      <InputField
        type={inputType}
        className={`form-control form-field position-relative ${inputClassName}`}
        value={value || ''}
        onChange={e => {
          if (onChange) onChange(e);
          autoSaveValue(e.target.value);
        }}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        bsSize={size}
        data-cy={dataCy}
        {...rest}
      />
      {type === 'password' && !disabled ? (
        <Button
          color="link"
          type="button"
          className={`input-wrapper__toggle-type ${value ? ' with-value' : ''}`}
          tabIndex={-1}
        >
          <SvgRender
            className={`text-${inputType === 'password' ? 'placeholder' : 'primary'}`}
            src={eyeSvg}
            style={{ width: 20, height: 20 }}
            onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')}
          />
        </Button>
      ) : null}
      {type === 'password' && showValidation ? (
        <PasswordValidation className="form-field-password-validation" password={value} />
      ) : null}
    </FormGroupWrap>
  );
};

export default Input;
