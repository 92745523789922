import { FilterType } from '@/ts-common/types/table-query';

export const LOGS_ENTITY_TYPES = {
  vessel_systems_group: 'vessel_systems_group',
  vessel_system: 'vessel_system',
  event: 'event',
  invoice: 'invoice',
  form_submission: 'form_submission',
  party: 'party',
  mga_period: 'mga_period',
  mga_action: 'mga_action',
  vessel: 'vessel',
  vessel_document: 'vessel_document',
  crew_contract: 'crew_contract',
  crew_rotation: 'crew_rotation',
  purchasing_requisition: 'purchasing_requisition',
  port_da_case: 'port_da_case'
} as const;

export type EntityType = (typeof LOGS_ENTITY_TYPES)[keyof typeof LOGS_ENTITY_TYPES];

export type AttributeFields = 'on_signer_id' | 'date_of_change' | 'port_id';

type EntityMeta = {
  display_name: string;
};
export interface EntityLog {
  acknowledged: boolean;
  created_at: string;
  changed_attributes?: AttributeFields[];
  created_by?: CreatedBy;
  entities?: EntityLog[];
  created_by_id: number;
  entity_id: number;
  entity_metadata?: EntityMeta;
  entity_type: string;
  parent_entity_id: number;
  parent_entity_type: string;
  request_method: string;
  request_url: string;
  service_action: string;
  service_category: string;
  service_subcategory: string;
  type: string;
  updated_at: string;
  _id: string;
}

export interface CreatedBy {
  full_name: string;
  id: number;
}

export type EntityLogsRequestParams = {
  filters: Array<FilterType>;
};
