import { useState } from 'react';
import { Button } from 'reactstrap';
import { EMAIL_REGEX } from 'common/utils/form/validation';
import PropTypes from 'prop-types';
import Input from 'common/components/form/inputs/Input';

import Modal from 'common/components/modals/BasicModal';

const EmailModal = ({ value, onChange, isModalOpen, toggleModal }) => {
  const [email, setEmail] = useState();
  const [error, setError] = useState();

  const onClear = () => {
    setEmail(null);
    setError('');
  };

  return (
    <Modal
      unmountOnClose
      backdropClassName="blurred-backdrop"
      container={'.purchasing-communications'}
      isOpen={isModalOpen}
      toggle={toggleModal}
      header={'Add Email'}
      footer={
        <>
          <Button
            color="cancel"
            onClick={() => {
              toggleModal();
              onClear();
            }}
          >
            CANCEL
          </Button>
          <Button
            disabled={!!error || !email}
            color="primary"
            onClick={() => {
              onChange([...(value || []), { id: email, supplier_email: email }]);
              toggleModal();
              onClear();
            }}
          >
            ADD
          </Button>
        </>
      }
      body={
        <div>
          <Input
            error={error}
            label="Email"
            onChange={e => {
              setEmail(e.target.value);
              setError(e.target.value?.match(EMAIL_REGEX) ? '' : 'Invalid Email');
            }}
            value={email}
          />
        </div>
      }
    />
  );
};

export default EmailModal;

EmailModal.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired
};
