import { useState, useEffect, useCallback, useMemo, FC } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import useRouter from 'use-react-router';

import Drawer, { DrawerHeader, FormDrawer, FormBody } from 'common/components/drawer';
import FormsDrawer from 'common/components/forms/drawer';
import Spinner from 'common/components/general/Spinner';
import * as ReviewProcessButton from './ReviewProcessToggleButton';
import History from './components/history';
import paths from 'routing/routes/_paths';

import { parseQueryParams } from 'common/utils/urls';
import {
  getEntityReviewProcess,
  setReviewProcessDrawerIsOpen,
  setReviewProcessFormDrawerIsOpen,
  createReviewProcessSubstepFormSubmission
} from 'common/components/review-process/store/actions';
import { getFormSubmission, updateFormSubmission } from 'store/forms/actions';
import { ReviewProcessEntityActionType } from '@/common/types/review-process';
import {
  selectActiveEntityId,
  selectReviewProcessDrawerIsOpen,
  selectReviewProcessFormDrawerIsOpen,
  selectReviewProcessId,
  selectReviewProcessSteps,
  selectReviewProcessName
} from './store/selectors-ts';
import ReviewProcessSteps from './steps';

export const ReviewProcessToggleButton = ReviewProcessButton;

const hiddenFields = { type: true };

type ReviewProcessDrawerProps = {
  entityAction: ReviewProcessEntityActionType;
  onDrawerCloseCallback?: () => void;
};

const ReviewProcessDrawer: FC<ReviewProcessDrawerProps> = ({
  entityAction = null, // Referring to src/common/components/review-process/entity-actions.js
  onDrawerCloseCallback = () => null
}) => {
  const [drawerType, setDrawerType] = useState('view');
  const { history, location } = useRouter();

  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedSubStepID, setSelectedSubStepID] = useState(null);
  const [selectedStepFormID, setSelectedStepFormID] = useState(null);
  const [selectedFormSubmissionID, setSelectedFormSubmissionID] = useState(null);

  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();

  const activeEntityId = useAppSelector(selectActiveEntityId);

  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, activeEntityId));

  const reviewProcessSteps = useAppSelector(state =>
    selectReviewProcessSteps(state, activeEntityId)
  );
  const reviewProcessDescription = useAppSelector(state =>
    selectReviewProcessName(state, activeEntityId)
  );

  const isDrawerOpen = useAppSelector(selectReviewProcessDrawerIsOpen);
  const isFormDrawerOpen = useAppSelector(selectReviewProcessFormDrawerIsOpen);

  useEffect(() => {
    const { entityId } = parseQueryParams(history.location.search);

    if (entityId) {
      dispatch(setReviewProcessDrawerIsOpen(true, entityId));
    }
  }, [history.location.search, dispatch]);

  const fetchReviewProcess = useCallback(async () => {
    if (!entityAction?.label) return;

    try {
      setLoading(true);

      await dispatch(
        getEntityReviewProcess({
          entity_id: activeEntityId,
          review_process_action: entityAction.label
        })
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }, [activeEntityId, dispatch, entityAction?.label]);

  useEffect(() => {
    if (isDrawerOpen && activeEntityId) {
      fetchReviewProcess();
    }
  }, [isDrawerOpen, activeEntityId, fetchReviewProcess]);

  useEffect(() => {
    if (!isDrawerOpen) {
      setSelectedForm(null);
    }
  }, [isDrawerOpen]);

  const onFormSelect = (form, formSubmission, step_form_id, sub_step_id) => {
    if (formSubmission) {
      window.open(`${paths.forms}/${formSubmission?.id}`, '_blank');
    } else {
      setSelectedForm(form);
      setSelectedStepFormID(step_form_id);
      setSelectedSubStepID(sub_step_id);
      setDrawerType(!formSubmission ? 'edit' : 'view');
      dispatch(setReviewProcessFormDrawerIsOpen(true));
    }
  };

  const onFormClose = useCallback(() => {
    setSelectedForm(null);
    setSelectedStepFormID(null);
    setSelectedSubStepID(null);
    setSelectedFormSubmissionID(null);

    setDrawerType('view');

    dispatch(setReviewProcessFormDrawerIsOpen(false));

    fetchReviewProcess();
  }, [dispatch, fetchReviewProcess]);

  const onFormSubmit = useCallback(
    async params => {
      if (selectedFormSubmissionID) {
        await dispatch(updateFormSubmission({ ...params, id: selectedFormSubmissionID }));
        await dispatch(getFormSubmission({ id: selectedFormSubmissionID }));
      } else {
        await dispatch(
          createReviewProcessSubstepFormSubmission({
            ...params,
            substep_id: selectedSubStepID,
            substep_form_id: selectedStepFormID
          })
        );
      }
    },
    [dispatch, selectedFormSubmissionID, selectedStepFormID, selectedSubStepID]
  );

  const onClose = () => {
    const { entityId } = parseQueryParams(history.location.search);
    // In case that we have constructed filters in the url we should change the logic a bit and use the stringifyObj so we can exclude only the entityId

    if (entityId) {
      history.replace(location.pathname);
    }

    dispatch(setReviewProcessDrawerIsOpen(false));
    onDrawerCloseCallback();
  };

  const formsDrawer = useMemo(() => {
    return { isOpen: isFormDrawerOpen && activeEntityId, open: () => null };
  }, [activeEntityId, isFormDrawerOpen]);

  return (
    <>
      <Drawer
        isOpen={isDrawerOpen && !!activeEntityId}
        close={onClose}
        size="sm"
        className={`review-process-drawer ${isFormDrawerOpen ? 'form-drawer-is-open' : ''}`}
      >
        <DrawerHeader>{reviewProcessDescription}</DrawerHeader>

        <FormDrawer>
          <FormBody className="p-4">
            {isDrawerOpen ? (
              <>
                {!reviewProcessId ? (
                  <Spinner />
                ) : reviewProcessId ? (
                  <div className="h-100p">
                    <ReviewProcessSteps
                      steps={reviewProcessSteps}
                      activeEntityId={activeEntityId}
                      loading={loading}
                      onFormSelect={onFormSelect}
                      fetchReviewProcess={fetchReviewProcess}
                    />

                    <div className="mt-3">
                      <History reviewProcessSteps={reviewProcessSteps} />
                    </div>
                  </div>
                ) : null}
              </>
            ) : null}
          </FormBody>
        </FormDrawer>
      </Drawer>
      <FormsDrawer
        drawerType={drawerType}
        drawer={formsDrawer}
        onDrawerClose={onFormClose}
        initialForm={selectedForm}
        initialFormSubmissionID={selectedFormSubmissionID}
        onSaveFormSubmission={onFormSubmit}
        hiddenFields={hiddenFields}
      />
    </>
  );
};

export default ReviewProcessDrawer;
