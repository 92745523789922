import SupplierSelector from '@/common/components/selectors/SupplierSelector';
import DeliveryProcessingSelector from '@/common/components/selectors/DeliveryProcessingSelector.tsx';
import PortSelector from 'common/components/selectors/PortSelector';

import deliveredIcon from '@/common/assets/svg/actions/to-be-delivered.svg';
import TopFilters from './TopFilters';

export const getColumns = () => [
  {
    header: 'Req No.',
    key: 'requisition_code',
    sort: false,
    canFilter: true,
    type: 'string',
    width: 1
  },
  {
    header: 'Requisition Description',
    key: 'requisition_description',
    sort: false,
    canFilter: false,
    width: 3
  },
  {
    header: 'Supplier',
    key: 'supplier',
    sort: false,
    canFilter: false,
    width: 2
  },
  {
    header: 'Delivery Port',
    key: 'delivery_port',
    className: 'justify-content-start cpt-4',
    sort: true,
    canFilter: false,
    width: 2
  },
  {
    header: 'Supplier',
    key: 'supplier_id',
    type: 'collection',
    component: SupplierSelector,
    componentRest: { isMulti: true },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Delivery Port',
    key: 'delivery_port_id',
    type: 'collection',
    component: PortSelector,
    componentRest: { isMulti: true },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Date Expected',
    key: 'delivery_date',
    type: 'date',
    className: 'justify-content-start cpt-4',
    sort: true,
    canFilter: true,
    width: 1
  },
  {
    header: 'Processing',
    key: 'processing',
    className: 'justify-content-start cpt-4',
    sort: false,
    canFilter: true,
    type: 'collection',
    componentRest: { isMulti: true },
    component: DeliveryProcessingSelector,
    width: 1
  },
  {
    header: 'Status',
    className: 'justify-content-start pt-1',
    key: 'status',
    sort: false,
    width: 2
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

const getTopFilters = () => [
  {
    name: 'status',
    operation: '=',
    value: null
  }
];

const config = {
  getColumns,
  getTopFilters,
  isNewTable: false,
  topFiltersComponent: TopFilters,
  tableStyle: 'default',
  icon: deliveredIcon,
  isModular: false,
  hideTableFilters: false,
  hideTableReorder: false,
  name: 'Deliverables',
  pageTitleComponent: null,
  entity: 'purchasing-deliverables'
};

export default config;
