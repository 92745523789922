import { useState, useEffect, useMemo, useCallback, FC } from 'react';
import PropTypes from 'prop-types';
import useRouter from 'use-react-router';
import { useSelector } from 'react-redux';

import { Button } from 'reactstrap';
import Drawer, { DrawerHeader, FormDrawer, FormBody, FormFooter } from 'common/components/drawer';

import FormManager from 'common/components/forms/_components/form-manager';
import _get from 'lodash/get';

import { getFormID, isFormAllowed } from 'common/components/forms/helpers';
import {
  getDigitalForm,
  getFormSubmission,
  getFormSubmissionPreviousValues,
  getLastSubmittedForm,
  deleteFormSubmission,
  getFormSubmissionDigitalSignatures,
  createFormSubmissionDigitalSignature,
  deleteFormSubmissionDigitalSignature
} from 'store/forms/actions';
import {
  getFormSubmissionWarnings,
  getFormSubmissionSignatureRequirements
} from '@/store/forms/actions-ts';
import { useActions, useUpdateEffect } from 'utils/hooks';
import { stringifyObj } from 'utils/urls';
import Chatbox from 'common/components/chatbox';
import {
  getFormSubmissionMessages,
  postFormSubmissioMessage,
  formSubmissionMarkAsRead,
  getFormSubmissionUnreadMessages
} from 'common/components/forms/chat/store/actions';
import chat from 'common/assets/svg/common/chat-bubble.svg';
import copyIcon from 'common/assets/svg/actions/copy.svg';
import { selectUnreadMessagesCount } from 'common/components/chatbox/store/selectors';
import MessagesCountBubble from '@/common/components/general/MessagesCountBubble';
import ToggleButton from 'common/components/general/ToggleButton';
import SvgRender from 'common/components/general/SvgRender';
import Priority from 'common/components/general/Priority';
import Departments from 'common/components/general/Departments';
import FormDeleteButton from 'common/components/forms/_components/FormDeleteButton';
import DigitalSignature from 'common/components/digital-signature';
import ReviewProcessToggleButton from 'common/components/review-process/ReviewProcessToggleButton';
import entityActions from 'common/components/review-process/entity-actions';
import * as reviewProcessActions from 'common/components/review-process/store/actions';
import { selectReviewProcessDrawerIsOpen } from 'common/components/review-process/store/selectors';
import { isReviewProcessCompleted } from 'common/components/review-process/store/selectors-ts';
import { selectReviewProcessId } from 'common/components/review-process/store/selectors-ts';
import { useDrawer } from 'common/components/drawer';
import ReviewProcessDrawer from 'common/components/review-process';
import Warning from 'common/components/forms/_components/Warning';
import { v4 as uuid } from 'uuid';

import ToggleLogsTimelineDrawerButton from '@/common/components/logs-timeline-drawer/ToggleLogsTimelineDrawerButton';
import LogsTimelineDrawer from '@/common/components/logs-timeline-drawer';
import { LOGS_ENTITY_TYPES } from '@/common/types/logs';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import permissions from '@/common/utils/permissions/constants';
import AuthCheck from '@/components/permissions/AuthCheck';
import { selectAccount } from 'store/account/selectors';
import Spinner from '@/ts-common/components/general/Spinner';
import { parseQueryParams } from '@/ts-common/utils/urls';
import NavigationButtons from '@/common/components/forms/drawer/NavigationButtons.tsx';
import paths from '@/routing/routes/_paths';
import {
  getIsFormUnlockedBasedOnStatus,
  getSubmittedFormCanBeDeleted
} from '@/common/components/forms/helpers-ts.ts';
import { isAuthorized } from '@/utils/permissions/authorize';

type FormsMatchParamsType = { form_id: string };

const FormsDrawer: FC<any> = ({
  hiddenFields = {}, // { vessel, type, ...}
  drawer,
  drawerType,
  onSubmitCallback,
  onSaveFormSubmission,
  onStatusChangeCallback,
  onDeleteCallback,
  onReadChatMessagesCallback,
  onDrawerClose,
  initialForm,
  initialFormSubmissionID,
  initialVesselID,
  excludeFormIds,
  className = '',
  previewMode,
  filterVesselSelectorOptions,
  hideCopyFromLastSubmission = false,
  isVesselLocked = false,
  isFormLocked = false,
  isOnLinkMode = false,
  customSelectorComponent,
  showDeleteButton = true,
  vesselSystemAssignmentId,
  showReviewProcessDrawer = true
}) => {
  const { history, match } = useRouter<FormsMatchParamsType>();
  const [showCloseConfirmationModal, setShowCloseConfirmationModal] = useState(false);
  const [isDrawerClosing, setIsDrawerClosing] = useState(false);
  const logsDrawer = useDrawer();
  const account = useSelector(selectAccount);

  const dispatch = useAppDispatch();

  const containerUniqueClassName = `form-drawer--${uuid()}`;

  const searchFormId = useMemo(
    () => (parseQueryParams(history.location.search) as any)?.form_id,
    [history.location.search]
  );

  const [formID, isSubmission] = useMemo(
    () =>
      getFormID({
        formSubmissionId: initialFormSubmissionID,
        initialFormId: initialForm?.id,
        paramsFormId: match.params.form_id,
        searchFormId
      }),
    [initialFormSubmissionID, initialForm?.id, searchFormId, match.params.form_id]
  );

  const [fetchingForm, setFetchingForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [warning, setWarning] = useState<any>();
  const [isEditing, setIsEditing] = useState(false);

  const [triggerFormSubmission, setTriggerFormSubmission] = useState(false);

  const [form, setForm] = useState(initialForm || null);
  const [formSubmission, setFormSubmission] = useState<any>(null);
  const [formSubmissionPreviousValues, setFormSubmissionPreviousValues] = useState<any>([]);
  const [selectedForm, setSelectedForm] = useState(form || null);

  const [lastSubmittedForm, setLastSubmittedForm] = useState<any>(null);
  const [lastSubmittedFormButtonLocked, setLastSubmittedFormButtonLocked] = useState(false);
  const [isLoadingLastSubmittedForm, setIsLoadingLastSubmittedForm] = useState(false);

  const [chatDrawerOpen, setChatDrawerOpen] = useState(false);

  const signatureDrawer = useDrawer(false);

  const [getEntityReviewProcess] = useActions([reviewProcessActions.getEntityReviewProcess]);

  const isOnboard = useAppSelector(state => state.isOnBoard);

  const unreadMessagesCount = useAppSelector(selectUnreadMessagesCount);

  const reviewProcessDrawerIsOpen = useAppSelector(selectReviewProcessDrawerIsOpen);
  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, formSubmission?.id));
  const isSubmissionReviewProcessCompleted = useAppSelector(state =>
    isReviewProcessCompleted(state, formSubmission?.id)
  );

  const getChatMessages = useCallback(async () => {
    if (!formSubmission?.id) return;

    dispatch(getFormSubmissionMessages({ id: formSubmission.id }));
  }, [dispatch, formSubmission?.id]);

  const fetchFormSubmissionWarnings = useCallback(async () => {
    if (!formSubmission?.id || !drawer.isOpen) return;

    try {
      const res = await dispatch(getFormSubmissionWarnings({ id: formSubmission?.id })).unwrap();
      setWarning(res?.length ? res[0] : null);
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, formSubmission?.id, drawer.isOpen]);

  const sendChatMessage = async (params: any) => {
    if (!formSubmission?.id) return;

    try {
      await dispatch(postFormSubmissioMessage({ id: formSubmission.id, ...params }));
    } catch (error) {
      console.error(error);
    }
  };

  const markChatMessagesAsRead = useCallback(async () => {
    if (!formSubmission?.id) return;

    try {
      await dispatch(formSubmissionMarkAsRead({ id: formSubmission.id }));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, formSubmission?.id]);

  const getUnreadMessages = useCallback(async () => {
    if (!formSubmission?.id) return;

    try {
      await dispatch(
        getFormSubmissionUnreadMessages({
          form_submission_ids: [formSubmission.id],
          saveUnreadMessages: true
        })
      );
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, formSubmission?.id]);

  const getReviewProcess = useCallback(async () => {
    if (reviewProcessDrawerIsOpen) return;

    const params = {
      entity_id: formSubmission?.id,
      review_process_action: entityActions.formsApproval.label
    };

    await getEntityReviewProcess(params);
  }, [formSubmission?.id, getEntityReviewProcess, reviewProcessDrawerIsOpen]);

  const clearFormIdFromLocationSearch = () => {
    const { form_id, ...rest } = parseQueryParams(history.location.search || '') as any;
    const search = stringifyObj(rest);

    return search;
  };

  const removeFormIdFromURL = () => {
    if (initialFormSubmissionID) return;

    if (match.params.form_id) {
      history.replace({
        pathname: history.location.pathname.replace(`/${match.params.form_id}`, ''),
        search: history.location.search
      });
    } else if (
      history.location.search &&
      (parseQueryParams(history.location.search) as any)?.form_id
    ) {
      history.replace({
        pathname: history.location.pathname,
        search: clearFormIdFromLocationSearch()
      });
    }
  };

  const getLastFormSubmission = async () => {
    if (!selectedForm?.id) return;

    try {
      setIsLoadingLastSubmittedForm(true);
      const res = await dispatch(getLastSubmittedForm({ id: selectedForm.id }));
      setLastSubmittedForm(res);
      setLastSubmittedFormButtonLocked(true);
      setIsLoadingLastSubmittedForm(false);
    } catch (error) {
      console.error(error);
      setLastSubmittedFormButtonLocked(false);
      setIsLoadingLastSubmittedForm(false);
    }
  };

  const getSignatureRequirements = useCallback(() => {
    if (!formSubmission?.id) return;

    return dispatch(getFormSubmissionSignatureRequirements({ id: formSubmission?.id }));
  }, [dispatch, formSubmission?.id]);

  const getForm = useCallback(
    async ({ id, isSubmission }: any) => {
      try {
        setFetchingForm(true);
        let res = null;
        let previousValuesRes = null;

        if (isSubmission) {
          res = await dispatch(getFormSubmission({ id }));

          setForm(res.form);
          setFormSubmission(res);
          setFetchingForm(false);

          if (res.form.type !== 'upload')
            previousValuesRes = await dispatch(getFormSubmissionPreviousValues({ id }));

          setFormSubmissionPreviousValues(previousValuesRes);
        } else {
          res = await dispatch(
            getDigitalForm({
              id: id,
              vessel_system_assignment_id: vesselSystemAssignmentId || undefined
            })
          );

          setForm(res);
          setFormSubmission(null);
          setFormSubmissionPreviousValues(null);
        }

        setFetchingForm(false);

        return res;
      } catch (e) {
        setFetchingForm(false);
      }
    },
    [dispatch, vesselSystemAssignmentId]
  );

  const initDrawer = useCallback(async () => {
    setTriggerFormSubmission(false);
    fetchFormSubmissionWarnings();
    setIsDrawerClosing(false);

    if (formID && drawer.isOpen) {
      try {
        await getForm({ id: formID, isSubmission });
        setIsEditing(drawerType === 'view' || drawerType === 'link_form' ? false : true);
      } catch (err) {
        console.error(err);
      }
    } else {
      setForm(initialForm || null);
      setFormSubmission(null);
      setFormSubmissionPreviousValues(null);
    }
  }, [
    drawerType,
    formID,
    initialForm,
    isSubmission,
    getForm,
    fetchFormSubmissionWarnings,
    drawer.isOpen
  ]);

  const onStatusChange = useCallback(
    (updated: any) => {
      setFormSubmission((prev: any) => (prev ? { ...prev, ...updated } : prev));
      if (onStatusChangeCallback) onStatusChangeCallback({ ...updated, formID, isSubmission });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formID, isSubmission]
  );

  useEffect(() => {
    if (formSubmission?.id) {
      getChatMessages();
      getUnreadMessages();

      if (!isOnboard) getReviewProcess();
    }
  }, [formSubmission?.id, getChatMessages, getReviewProcess, getUnreadMessages, isOnboard]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (formID) {
      timeout = setTimeout(() => drawer.open(), 200);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [formID]);

  useEffect(() => {
    setIsEditing(drawerType === 'view' ? false : true);
  }, [drawerType]);

  useEffect(() => {
    if (unreadMessagesCount > 0) {
      if (chatDrawerOpen) markChatMessagesAsRead();
      else if (onReadChatMessagesCallback) onReadChatMessagesCallback();
    }
  }, [chatDrawerOpen, markChatMessagesAsRead, onReadChatMessagesCallback, unreadMessagesCount]);

  useEffect(() => {
    if (drawer.isOpen && (!formSubmission?.id || !form?.id)) {
      initDrawer();
    } else {
      setIsEditing(false);
    }
  }, [drawer.isOpen, initDrawer, formSubmission?.id, form?.id]);

  useUpdateEffect(() => {
    if (!drawer.isOpen) {
      setForm(null);
      setFormSubmission(null);
      setFormSubmissionPreviousValues(null);

      if (!onDrawerClose) removeFormIdFromURL();
    }
  }, [drawer.isOpen]);

  const onTriggerFormSubmission = () => {
    setTriggerFormSubmission(true);

    setTimeout(() => setTriggerFormSubmission(false), 200);
  };

  const onCloseDrawerHandler = () => {
    setIsDrawerClosing(false);
    setShowCloseConfirmationModal(false);

    logsDrawer.close();

    if (onDrawerClose) {
      onDrawerClose();
    } else {
      drawer.close();
      setTimeout(() => removeFormIdFromURL(), 200);
    }

    setWarning(null);
  };

  const deleteForm = async () => {
    try {
      await dispatch(deleteFormSubmission({ id: formSubmission?.id }));

      if (onDeleteCallback) onDeleteCallback();
      onCloseDrawerHandler();
    } catch (err) {
      console.error(err);
    }
  };

  const onReviewProcessDrawerClose = async () => {
    // We want to get the new submission status only when the review process is completed
    if (
      !formSubmission?.id ||
      !isSubmissionReviewProcessCompleted ||
      formSubmission?.status?.progress === 'reviewed' // we should refetch only if the status hasn't changed already after review process completion
    )
      return;

    try {
      await getForm({ id: formSubmission.id, isSubmission: true });
    } catch (err) {
      console.error(err);
    }
  };

  const isLocked = useMemo(() => {
    return !isOnLinkMode
      ? isFormLocked ||
          !getIsFormUnlockedBasedOnStatus(formSubmission?.status, formSubmission, isOnboard)
      : true;
  }, [isOnLinkMode, isFormLocked, formSubmission, isOnboard]);

  return (
    <>
      <Drawer
        {...drawer}
        size="lg"
        className={`forms-listing-drawer ${containerUniqueClassName} ${
          chatDrawerOpen || signatureDrawer.isOpen || logsDrawer.isOpen || reviewProcessDrawerIsOpen
            ? 'chat-is-open'
            : ''
        } ${(selectedForm || formSubmission?.id) && !previewMode ? 'has-form' : ''} ${className}`}
        dontCloseOnClickOutside={true}
        close={() => setIsDrawerClosing(true)}
      >
        <DrawerHeader>
          <div
            className={`d-flex align-items-center ${
              hideCopyFromLastSubmission && !previewMode && drawerType === 'add' ? 'cmt-6' : ''
            }`}
          >
            <div className="d-flex align-items-center flex-1">
              {previewMode ? (
                <div className="d-flex align-items-center">
                  <Priority
                    className="me-1 cpb-4"
                    size="large"
                    value={initialForm?.importance?.label}
                    withText={false}
                  />
                  <div>
                    {_get(initialForm, 'name')}{' '}
                    {initialForm?.revision ? ` - ${initialForm.revision}` : ''}{' '}
                  </div>
                  <span className=" text-primary fw-bold me-4">/ PREVIEW</span>
                  <Departments values={[initialForm?.department]} />
                </div>
              ) : drawerType === 'add' ? (
                ' Fill in new form'
              ) : drawerType === 'link_form' ? (
                'Link to existing Form'
              ) : (
                'Close'
              )}

              {history.location.pathname.includes(paths.forms) ? (
                <NavigationButtons formSubmissionId={formSubmission?.id} getForm={getForm} />
              ) : null}

              {warning?.action ? (
                <span className="mx-3 small-separator cpy-12 bg-platinum"></span>
              ) : null}

              <Warning
                action={warning?.action}
                rankName={warning?.crew_rank?.name}
                fullName={warning?.full_name}
                minutesAgo={warning?.minutes_ago}
              />
            </div>

            {!fetchingForm &&
            formSubmission &&
            reviewProcessId &&
            !reviewProcessDrawerIsOpen &&
            !isOnboard ? (
              <div className="ms-auto cme-12">
                <ReviewProcessToggleButton
                  entityId={formSubmission?.id}
                  disabled={isSubmitting}
                  withDrawer={false}
                />
              </div>
            ) : null}
            {formSubmission ? (
              <div className="d-inline-flex align-items-center">
                {!isOnLinkMode && !fetchingForm && !previewMode ? (
                  <>
                    <DigitalSignature
                      viewOnly={isLocked}
                      drawer={signatureDrawer}
                      onCreate={async (party_id, signature) =>
                        await dispatch(
                          createFormSubmissionDigitalSignature({
                            id: formSubmission?.id,
                            party_id,
                            signature
                          })
                        )
                      }
                      onFetch={async () =>
                        await dispatch(
                          getFormSubmissionDigitalSignatures({ id: formSubmission?.id })
                        )
                      }
                      onFetchRequirements={getSignatureRequirements}
                      refetchWrapper={async () => await getForm({ id: formID, isSubmission })}
                      onDelete={async signature_id =>
                        await dispatch(
                          deleteFormSubmissionDigitalSignature({
                            id: formSubmission?.id,
                            signature_id
                          })
                        )
                      }
                    />
                    <div className="me-3 pe-3 border-end height-24"></div>
                  </>
                ) : null}

                {!isOnboard && <ToggleLogsTimelineDrawerButton onClick={logsDrawer.open} />}

                {!isOnLinkMode ? (
                  <AuthCheck
                    permissions={[
                      permissions.office.forms.chat.view,
                      permissions.onboard.forms.view
                    ]}
                  >
                    <ToggleButton
                      className={`${!isOnboard && 'ms-1'} position-relative chat-forms-button`}
                      onClick={() => setChatDrawerOpen(true)}
                    >
                      <img alt="chatbox-icon" src={chat} width={16} height={15} />
                      {unreadMessagesCount > 0 ? (
                        <MessagesCountBubble
                          className={`messages-notification ${
                            unreadMessagesCount > 9 ? 'cps-2 cpe-2' : 'cps-4 cpe-4'
                          } position-absolute`}
                          count={unreadMessagesCount}
                        />
                      ) : null}
                    </ToggleButton>
                  </AuthCheck>
                ) : null}
              </div>
            ) : !fetchingForm && selectedForm && !previewMode && !hideCopyFromLastSubmission ? (
              <AuthCheck
                permissions={[permissions.office.forms.edit_values, permissions.onboard.forms.view]}
              >
                <Button
                  onClick={getLastFormSubmission}
                  disabled={lastSubmittedFormButtonLocked || isLoadingLastSubmittedForm}
                  color="white"
                  type="button"
                  className="d-inline-flex align-items-center justify-content-center cme-12 
                  cpy-4 cpx-12 fs-12 text-primary border-0 fw-medium"
                >
                  <SvgRender className="cme-12" src={copyIcon} style={{ height: 12, width: 12 }} />
                  {lastSubmittedFormButtonLocked
                    ? 'Copied from Last Submitted form'
                    : 'Copy from Last Submitted form'}
                </Button>
              </AuthCheck>
            ) : null}
          </div>
        </DrawerHeader>

        <FormDrawer>
          <FormBody>
            {(formID && !form) || fetchingForm ? (
              <Spinner className="ms-2" />
            ) : drawer.isOpen ? (
              <FormManager
                selectedForm={selectedForm}
                setSelectedForm={setSelectedForm}
                form={form}
                isVesselLocked={isVesselLocked}
                formSubmission={formSubmission}
                setShowCloseConfirmationModal={setShowCloseConfirmationModal}
                showCloseConfirmationModal={showCloseConfirmationModal}
                formSubmissionPreviousValues={formSubmissionPreviousValues}
                lastSubmittedForm={lastSubmittedForm}
                setLastSubmittedForm={setLastSubmittedForm}
                setLastSubmittedFormButtonLocked={setLastSubmittedFormButtonLocked}
                isLoadingLastSubmittedForm={isLoadingLastSubmittedForm}
                triggerFormSubmission={triggerFormSubmission}
                excludeFormIds={excludeFormIds}
                closeDrawer={onCloseDrawerHandler}
                isDrawerClosing={isDrawerClosing}
                setIsDrawerClosing={setIsDrawerClosing}
                isDrawerOpen={drawer.isOpen}
                onSubmitCallback={onSubmitCallback}
                onStatusChangeCallback={onStatusChange}
                onSaveFormSubmission={onSaveFormSubmission}
                refetchFormSubmission={async () => await getForm({ id: formID, isSubmission })}
                hiddenFields={hiddenFields}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
                previewMode={previewMode}
                isLocked={isLocked}
                filterVesselSelectorOptions={filterVesselSelectorOptions}
                initialVesselID={initialVesselID}
                isOnLinkMode={isOnLinkMode}
                customSelectorComponent={customSelectorComponent}
                containerUniqueClassName={containerUniqueClassName}
              />
            ) : null}
          </FormBody>

          {!fetchingForm && !previewMode && (isOnLinkMode ? !isFormLocked : !isLocked) && (
            <FormFooter>
              {!customSelectorComponent &&
              drawerType !== 'link_form' &&
              formSubmission?.id &&
              formSubmission?.origin === null &&
              showDeleteButton &&
              getSubmittedFormCanBeDeleted(
                formSubmission.status,
                !!formSubmission.vessel,
                isOnboard
              ) ? (
                (!isOnboard &&
                  isAuthorized(account, [permissions.office.forms.delete]) &&
                  isFormAllowed(
                    account,
                    permissions.office.forms.delete,
                    formSubmission.form.uid
                  )) ||
                (isOnboard && isAuthorized(account, [permissions.onboard.forms.delete])) ? (
                  <FormDeleteButton deleteForm={deleteForm} />
                ) : null
              ) : null}
              {isEditing || (customSelectorComponent && drawerType === 'link_form') ? (
                <>
                  <Button
                    color="cancel"
                    className="p-0 fs-10 me-2 ms-auto"
                    onClick={() => setIsDrawerClosing(true)}
                  >
                    CANCEL
                  </Button>
                  <Button
                    onClick={onTriggerFormSubmission}
                    disabled={isSubmitting}
                    color="primary"
                    className="px-2 height-28 fs-10 d-flex align-items-center"
                  >
                    {drawerType === 'link_form' ? (isEditing ? 'SAVE & LINK' : 'LINK') : 'SAVE'}
                  </Button>
                </>
              ) : null}
            </FormFooter>
          )}
        </FormDrawer>
      </Drawer>

      <Chatbox
        drawerClassName="forms-chatbox"
        getMessages={getChatMessages}
        sendMessage={sendChatMessage}
        getUnreadMessages={getUnreadMessages}
        markMessagesAsRead={markChatMessagesAsRead}
        fetchOnMount={false}
        permissions={[permissions.office.forms.chat.create, permissions.onboard.forms.view]}
        hideChatToggle={true}
        drawer={{
          isOpen: chatDrawerOpen,
          open: () => null,
          close: () => setChatDrawerOpen(false)
        }}
      />

      {showReviewProcessDrawer && formSubmission && !isOnboard ? (
        <ReviewProcessDrawer
          entityAction={entityActions.formsApproval}
          onDrawerCloseCallback={onReviewProcessDrawerClose}
        />
      ) : null}

      <LogsTimelineDrawer
        drawer={logsDrawer}
        entityId={formSubmission?.id}
        entityType={LOGS_ENTITY_TYPES.form_submission}
        excludedEntityActions={['viewed']}
      />
    </>
  );
};

FormsDrawer.propTypes = {
  drawer: PropTypes.shape({
    isOpen: PropTypes.bool, // Use false to disabled the type select
    open: PropTypes.func // Use false to hide vessel
  }),
  isVesselLocked: PropTypes.bool,
  drawerType: PropTypes.oneOf(['add', 'edit', 'view', 'link_form']),
  onDrawerClose: PropTypes.func, // use to overide the default on drawer.close function that removes the form_id from the URL
  hideCopyFromLastSubmission: PropTypes.bool,
  isOnLinkMode: PropTypes.bool,

  initialForm: PropTypes.object, // use to set an initial selected form
  initialFormSubmissionID: PropTypes.number, // use to set an initail form submission ID, when the form_id is not in the URL
  showDeleteButton: PropTypes.bool,
  vesselSystemAssignmentId: PropTypes.number, // the selected vessel system assignment id from pms jobs - used to get specified system attributes and fill in the PMS System Attribute form inputs
  showReviewProcessDrawer: PropTypes.bool
};

export default FormsDrawer;
