import { useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormState, useUpdateEffect } from 'utils/hooks';

import DepartmentsActions from './DepartmentsActions';
import Textarea from '@/ts-common/components/form/inputs/Textarea';
import ShadowBox from 'common/components/general/ShadowBox';
import ApproveButton from './ApproveButton';
import RejectButton from './RejectButton';

import PageSaving from 'common/components/general/PageSaving';
import Form from './Form';

import {
  selectActiveEntityId,
  selectReviewProcessId
} from 'common/components/review-process/store/selectors';
import xIcon from 'common/assets/svg/common/x.svg';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';
import {
  updateReviewProcessSubstepRemarks,
  approveReviewProcessSubstep,
  revokeReviewProcessSubstep,
  rejectReviewProcessStep
} from 'common/components/review-process/store/actions';
import { useAppDispatch } from '@/store/hooks';
import StepDynamicSetup from '../components/StepDynamicSetup';

const SubStep = ({
  subStep,
  subStepState,
  onFormSelect,
  isDisabled,
  isDynamic,
  isMultipleDynamic,
  entityId,
  refetchDrawer,
  index,
  stepId,
  stepIndex,
  isConfiguringStep,
  steps,
  dynamicStepCanBeConfigured,
  dynamicStepIsAlreadyConfigured,
  onRemoveSubStep
}) => {
  const {
    can_approve,
    can_reject,
    can_revoke,
    departments,
    department_roles,
    has_remarks,
    id,
    completed_by,
    text_after_completion,
    text_before_completion
  } = subStep;
  const activeEntityId = useSelector(selectActiveEntityId);
  const reviewProcessId = useSelector(state => selectReviewProcessId(state, activeEntityId));
  const dispatch = useAppDispatch();

  const { fields, selectField } = useFormState(subStepState);

  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [modal, setModal] = useState({ isOpen: false, type: null, extraParams: null });

  const handleApprove = async () => {
    try {
      if (completed_by) {
        setIsAutoSaving(true);

        await dispatch(revokeReviewProcessSubstep({ substep_id: id }));
      } else {
        setIsAutoSaving(true);

        await dispatch(approveReviewProcessSubstep({ substep_id: id }));
      }

      await refetchDrawer();

      setIsAutoSaving(false);
    } catch (error) {
      setIsAutoSaving(false);
    }
  };

  const handleReject = async params => {
    const { start_from_step_id } = params;
    if (!reviewProcessId) return;

    try {
      setIsAutoSaving(true);

      await dispatch(
        rejectReviewProcessStep({
          start_from_step_id,
          step_id: stepId,
          review_process_id: reviewProcessId
        })
      );
      await refetchDrawer();

      setIsAutoSaving(false);
    } catch (error) {
      setIsAutoSaving(false);
    }
  };

  const modalHandler = async params => {
    setModal(params);
  };

  const onSaveRemarks = useCallback(
    async params => {
      try {
        setIsAutoSaving(true);

        await dispatch(updateReviewProcessSubstepRemarks({ ...params, entityId: entityId }));

        setIsAutoSaving(false);
      } catch (e) {
        setIsAutoSaving(false);
      }
    },
    [entityId, dispatch]
  );

  useUpdateEffect(() => {
    if (!isAutoSaving && !subStep?.remarks) {
      /* When a substep is rejected, its remarks are wiped out */
      selectField('remarks')('');
    }
  }, [isAutoSaving, subStep?.remarks]);

  const constructBodyModal = useCallback(() => {
    if (modal.extraParams?.isFirstStep) {
      return (
        <div>
          Are you sure you want to reject <span className="fw-bold">Step {stepIndex + 1}</span> and
          start from the beginning of the Process?
        </div>
      );
    } else {
      return (
        <div>
          Are you sure you want to reject&nbsp;
          <span className="fw-bold">Step {stepIndex + 1}</span>
          &nbsp;and start from the&nbsp;
          <span className="fw-bold">Step {modal.extraParams?.start_from_step_index}</span> ?
        </div>
      );
    }
  }, [modal.extraParams, stepIndex]);

  const bodyModal = useMemo(() => constructBodyModal(), [constructBodyModal]);

  if (
    (isDynamic || isMultipleDynamic) &&
    ((dynamicStepCanBeConfigured && !dynamicStepIsAlreadyConfigured) ||
      (dynamicStepCanBeConfigured && dynamicStepIsAlreadyConfigured && isConfiguringStep))
  )
    return (
      <StepDynamicSetup
        isMultipleDynamic={isMultipleDynamic}
        isConfiguringStep={isConfiguringStep}
        index={index}
        fields={fields}
        selectField={selectField}
        onRemoveSubStep={onRemoveSubStep}
        eligibleDepartments={steps[stepIndex].substeps[0].eligible_departments}
        eligibleRoles={steps[stepIndex].substeps[0].eligible_department_roles}
      />
    );

  return (
    <>
      <ShadowBox flat className={`cpx-12 cpy-12 ${index !== 0 ? 'mt-1' : ''}`}>
        <PageSaving isSaving={isAutoSaving} />
        <DepartmentsActions
          departments={departments}
          departmentRoles={department_roles}
          index={index}
        />

        {has_remarks ? (
          <Textarea
            label="Remarks"
            placeholder="Add some text"
            readOnly={isDisabled || !can_approve}
            onChange={e => selectField('remarks')(e.target.value)}
            onAutoSave={remarks => onSaveRemarks({ substep_id: subStep.id, remarks })}
            rows={4}
            className="mb-1"
            {...fields.remarks}
          />
        ) : null}
        {subStep.substep_forms?.map((substepForm, index) => (
          <div
            key={substepForm?.id}
            className={`cmt-20 ${index === subStep.substep_forms?.length - 1 ? 'mb-1' : ''} `}
          >
            <Form substepForm={substepForm} onFormSelect={onFormSelect} subStepId={id} />
          </div>
        ))}
        <div className="d-flex align-items-center justify-content-end">
          <RejectButton
            modalHandler={modalHandler}
            isDisabled={isDisabled}
            canReject={can_reject}
            setModal
            currentStepIndex={stepIndex}
            steps={steps}
          />
          <ApproveButton
            handleApprove={handleApprove}
            isDisabled={isDisabled}
            completedBy={completed_by?.full_name || ''}
            textBeforeCompletion={text_before_completion}
            textAfterCompletion={text_after_completion}
            canApproveStep={can_approve}
            canRevokeApprovedStep={can_revoke}
            isCompleted={!!completed_by}
          />
        </div>
      </ShadowBox>

      <DangerousActionModal
        transparent
        action={'delete'}
        actionIcon={
          <SvgRender src={xIcon} style={{ width: 60, height: 60 }} className="text-red" />
        }
        onAccept={() => handleReject(modal.extraParams)}
        closeModal={() => modalHandler({ isOpen: false, type: null, extraParams: null })}
        isOpen={modal.isOpen}
        actionText={'REJECT'}
        header={'Reject step'}
        body={bodyModal}
      />
    </>
  );
};

export default SubStep;
