import { Form, FormSubmission } from '@/common/types/form-submissions';
import { Step } from '@/common/types/review-process';
import { FC, useMemo, useState } from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  selectReviewProcessHasConfiguredMultipleDynamicSteps,
  selectReviewProcessMultipleDynamicStep
} from '../store/selectors-ts';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import StepForm from './Step';

type ReviewProcessStepsProps = {
  steps: Step[];
  activeEntityId: number | string;
  disabled?: boolean;
  loading: boolean;
  onFormSelect: (
    form: Form,
    formSubmission: FormSubmission,
    step_form_id: number,
    sub_step_id: number
  ) => void;
  fetchReviewProcess: () => void;
};

const ReviewProcessSteps: FC<ReviewProcessStepsProps> = ({
  steps,
  activeEntityId,
  loading,
  disabled,
  onFormSelect,
  fetchReviewProcess
}) => {
  const [isAddingStep, setIsAddingStep] = useState<boolean>(false);

  const reviewProcessMultipleDynamicStep = useAppSelector(state =>
    selectReviewProcessMultipleDynamicStep(state, activeEntityId)
  );
  const reviewProcessHasConfiguredMultipleDynamicSteps = useAppSelector(state =>
    selectReviewProcessHasConfiguredMultipleDynamicSteps(state, activeEntityId)
  );

  const reviewProcessSteps = useMemo(() => {
    if (reviewProcessMultipleDynamicStep) {
      const newDynamicStep = {
        ...reviewProcessMultipleDynamicStep,
        id: null // The new-to-be-added step has no ID yet
      };

      /*
        We use the newDynamicStep to render (visually) the multiple-dyncamic step. We do this in cases where:
        1. we are creating a new multiple-dyncamic step (isAddingStep = true)
        2. we haven't configured any multiple-dyncamic steps yet
      */

      if (reviewProcessHasConfiguredMultipleDynamicSteps) {
        return isAddingStep
          ? [...steps, { ...newDynamicStep, is_configuring_initial_value: true }]
          : steps;
      } else {
        return [...steps, newDynamicStep];
      }
    }

    return steps;
  }, [
    isAddingStep,
    reviewProcessHasConfiguredMultipleDynamicSteps,
    reviewProcessMultipleDynamicStep,
    steps
  ]);

  return (
    <>
      <div>
        {reviewProcessSteps.map((step, index) => (
          <StepForm
            key={step.id}
            index={index}
            step={step}
            steps={reviewProcessSteps}
            loading={loading}
            disabled={disabled}
            entityId={activeEntityId}
            onFormSelect={onFormSelect}
            refetchDrawer={fetchReviewProcess}
            setIsAddingStep={setIsAddingStep}
          />
        ))}
      </div>

      {reviewProcessMultipleDynamicStep &&
      reviewProcessMultipleDynamicStep.substeps[0].can_edit_reviewer &&
      reviewProcessHasConfiguredMultipleDynamicSteps ? (
        <div className="d-flex mt-1">
          <CircledButton
            type="add"
            label="Add step"
            disabled={loading || isAddingStep}
            onClick={() => setIsAddingStep(true)}
            svgStyle={{ width: 8, height: 8 }}
            size={15}
            className="fs-12 fw-bold text-primary"
          />
        </div>
      ) : null}
    </>
  );
};

export default ReviewProcessSteps;
