import PropTypes from 'prop-types';
import { Department, DepartmentRole, Party } from '../parties/PartyTypes';
import { stepTypes } from '@/common/components/review-process/entity-actions';

export const Histories = PropTypes.shape({
  action: PropTypes.oneOf(['completed, rejected']),
  created_at: PropTypes.string,
  id: PropTypes.number.isRequired,
  performed_at: PropTypes.string,
  performed_by: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string,
    department_roles: PropTypes.arrayOf(DepartmentRole),
    departments: PropTypes.arrayOf(Department)
  }).isRequired,
  performed_by_id: PropTypes.number,
  review_process_substep_id: PropTypes.number,
  updated_at: PropTypes.string,
  user_department_roles: PropTypes.arrayOf(DepartmentRole),
  user_departments: PropTypes.arrayOf(Department)
});

export const Substep = PropTypes.shape({
  id: PropTypes.number.isRequired,
  review_process_step_id: PropTypes.number.isRequired,
  has_remarks: PropTypes.bool,
  remarks: PropTypes.string,
  text_before_completion: PropTypes.string,
  text_after_completion: PropTypes.string,
  completed_by_id: PropTypes.number,
  completed_at: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  departments: PropTypes.arrayOf(Department),
  department_roles: PropTypes.arrayOf(DepartmentRole),
  completed_by: Party,
  histories: PropTypes.arrayOf(Histories)
});

export const Step = PropTypes.shape({
  created_at: PropTypes.string,
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf([stepTypes.static, stepTypes.dynamic, stepTypes.multiple_dynamic]),
  is_required: PropTypes.bool.isRequired,
  review_process_id: PropTypes.number.isRequired,
  substeps: PropTypes.arrayOf(Substep),
  updated_at: PropTypes.string
});
