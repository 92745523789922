import { get, post } from '@/api';
import { CaptainReportsAlarmsDue } from '@/common/types/captain-reports';
import { Event } from '@/common/types/events';

type createOffHireEventFromStoppageReportParamsType = {
  id: number;
  template_id: number;
  name: string;
};

export const createOffHireEventFromStoppageReport = async ({
  id,
  ...rest
}: createOffHireEventFromStoppageReportParamsType) => {
  return (await post<Event>(`/captain-reports/${id}/stoppage/off-hire`, rest)).data;
};

export type GetCaptainReportsAlarmsDueParams = { vessel_id?: number };

export const getCaptainReportsAlarmsDue = async (params: GetCaptainReportsAlarmsDueParams) => {
  return (await get<CaptainReportsAlarmsDue>(`/captain-report-alarms-due`, params)).data;
};
