import { ChangeEventHandler, FC, ReactNode } from 'react';
import { Input, InputProps } from 'reactstrap';

import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import useAutoSaveInputValue from '@/ts-common/utils/hooks/useAutoSaveInputValue';
// import TextareaAutosize from 'react-autosize-textarea';

interface TextareaProps extends Omit<InputProps, 'value'> {
  error?: string | null;
  className?: string;
  viewOnly?: boolean;
  label?: ReactNode | string;
  rows?: number;
  maxLength?: number;
  invisible?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value: string | null;
  onChange?: ChangeEventHandler<HTMLInputElement> | undefined;
  onAutoSave?: (value: string) => Promise<unknown>;
  // autoResize?: boolean;
}

const Textarea: FC<TextareaProps> = ({
  error,
  className = '',
  value,
  label,
  placeholder,
  rows,
  maxLength,
  readOnly,
  disabled,
  // autoResize,
  invisible,
  onChange,
  onAutoSave = () => new Promise(resolve => resolve(null)),
  ...rest
}) => {
  // const Tag = autoResize ? TextareaAutosize : Input;
  const Tag = Input;
  const { onAutoSave: autoSaveValue } = useAutoSaveInputValue(onAutoSave);

  return (
    <FormGroupWrap
      className={`${invisible ? 'invisible-input' : ''} ${className}`}
      label={label}
      error={error}
    >
      <div className="position-relative flex-grow-1">
        <Tag
          type={'textarea'}
          value={value || ''}
          onChange={e => {
            if (onChange) onChange(e);
            autoSaveValue(e.target.value);
          }}
          placeholder={placeholder ? placeholder : ''}
          rows={rows ? rows : 3}
          maxLength={maxLength}
          readOnly={readOnly || false}
          disabled={disabled || false}
          className={`form-control form-field gray-scrollbar ${disabled ? 'disabled' : ''}`}
          {...rest}
        />
      </div>
    </FormGroupWrap>
  );
};

export default Textarea;
