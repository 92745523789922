import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { css } from '@emotion/react';
import { ProfileDrawer } from 'common/components/drawer';
import { useForm } from 'common/utils/hooks/useForm';
import { useFormState } from 'utils/hooks';
import { DrawerState } from 'common/entities/drawer/DrawerTypes';
import NavigationArrows from 'common/components/general/NavigationArrows';
import Spinner from '@/ts-common/components/general/Spinner';
import { getFinding } from 'common/components/findings/store/actions';

import { selectEventId } from 'events/store/events/selectors';
import { createReportFinding, updateFinding } from 'common/components/findings/store/actions';

import { useDispatch } from 'react-redux';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import useRouter from 'use-react-router';
import paths from 'routing/routes/_paths';
import { createEventAuditFinding } from 'events/store/event-modules/audits/actions';
import DeleteFindingAction from './DeleteFindingAction';

import config from './config';
import Sidebar from './Sidebar';
import Body from './Body';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

const FindingForm = ({
  drawer,
  activeId,
  navigation,
  setNavigation,
  auditId = null,
  eventAuditId = null,
  isOnBoard = false,
  isOnEvent = false,
  active,
  setActive,
  eventVessel,
  refetchData
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const vessel = eventVessel || active?.vessel;
  const [saveModalIsOpen, setSaveModalIsOpen] = useState(false);
  const [purchasingCaseDrawerIsOpen, setPurchasingCaseDrawerIsOpen] = useState(false);

  const { formState, collectValues, loadValues, resetForm, handleSubmitError } = useForm(config);
  const { fields, changeField, selectField } = useFormState(formState);
  const { match, history } = useRouter();

  const eventId = useSelector(selectEventId);
  const dispatch = useDispatch();
  const hideActions = isOnBoard && active;

  const initActiveFinding = useCallback(
    async id => {
      try {
        setIsFetching(true);
        const res = await dispatch(getFinding({ id })).unwrap();
        setIsFetching(false);

        setActive(res?.data);

        setNavigation(res?.navigation);
      } catch (error) {
        setIsFetching(false);
        setActive(null);
      }
    },
    [dispatch, drawer, setActive, setNavigation]
  );

  useEffect(() => {
    if (activeId) {
      drawer.open();

      if (!isOnEvent) {
        initActiveFinding(activeId);
      }
    } else {
      setActive(null);
      setNavigation({ selected_id: null, next_id: null, previous_id: null });
      resetForm();
    }
  }, [activeId, initActiveFinding, isOnEvent, resetForm]);

  const onClose = () => {
    if (history.location.pathname.startsWith(paths.findings) && match.params.id) {
      history.replace({
        pathname: paths.findings,
        search: history.location.search
      });
    }

    if (isOnEvent) {
      setActive(null);
    }

    drawer.close();
  };

  const onSubmit = async type => {
    const values = collectValues();
    if (!values) return;

    try {
      setIsSubmitting(true);

      const {
        category,
        subcategory,
        tags,
        jobs,
        form_submissions,
        purchasing_requisitions,
        attachments,
        origin_audit,
        ...rest
      } = values;

      const params = {
        event_id: eventId,
        finding_category_id: category?.id,
        finding_subcategory_id: subcategory?.id,
        finding_tag_ids: (tags || [])?.map(({ id }) => id),
        job_ids: (jobs || [])?.map(({ id }) => id),
        form_submission_ids: (form_submissions || [])?.map(({ id }) => id),
        purchasing_requisition_ids: (purchasing_requisitions || [])?.map(({ id }) => id),
        attachments: attachments?.map(({ id }) => id),
        ...rest
      };

      if (active?.id) {
        await dispatch(updateFinding({ ...params, finding_id: active?.id, auditId })).unwrap();
      } else if (isOnBoard) {
        await dispatch(
          createReportFinding({
            finding_category_id: category?.id,
            finding_subcategory_id: subcategory?.id,
            finding_tag_ids: (tags || [])?.map(tag => tag.id),
            attachments: attachments?.map(attachment => attachment.id),
            isOnBoard: isOnBoard,
            ...rest
          })
        ).unwrap();
        setSaveModalIsOpen(false);
      } else {
        await dispatch(createEventAuditFinding({ ...params, audit_id: auditId })).unwrap();
      }

      if (type === 'add_more') {
        resetForm();
        setActive(null);
      } else {
        onClose();
      }

      if (isOnEvent) {
        setActive(null);
      }
      setIsSubmitting(false);
      refetchData();
    } catch (error) {
      handleSubmitError(error);
      setIsSubmitting(false);
    }
  };

  const onTogglePurchasingCaseDrawer = useCallback(
    isOpen => setPurchasingCaseDrawerIsOpen(isOpen),
    []
  );

  useEffect(() => {
    if (drawer?.isOpen && active) {
      loadValues(active);
    } else {
      setActive(null);
      resetForm();
      if (eventAuditId) {
        selectField('origin_audit')({ event_audit_id: eventAuditId });
      }
    }
  }, [drawer?.isOpen, resetForm, loadValues, selectField, active, eventAuditId, setActive]);

  return (
    <ProfileDrawer
      drawer={{ ...drawer, close: onClose }}
      size="lg"
      header={
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {hideActions ? 'View' : activeId ? 'Edit' : 'Add'} Finding{' '}
            {active ? (
              <NavigationArrows
                className="mb-0 ps-3 ms-2 border-start"
                onNextClick={
                  isOnEvent
                    ? () => setNavigation({ selected_id: navigation?.next_id })
                    : () => history.replace(`${paths.findings}/${navigation?.next_id}`)
                }
                disabledNext={!navigation?.next_id || isFetching}
                onPreviousClick={
                  isOnEvent
                    ? () => setNavigation({ selected_id: navigation?.previous_id })
                    : () => history.replace(`${paths.findings}/${navigation?.previous_id}`)
                }
                disabledPrevious={!navigation?.previous_id || isFetching}
              />
            ) : null}
          </div>
          <div>{vessel?.name || ''}</div>
        </div>
      }
      leftSide={
        isFetching ? (
          <Spinner />
        ) : (
          <Sidebar
            changeField={changeField}
            selectField={selectField}
            isOnBoard={isOnBoard}
            active={active}
            {...fields}
          />
        )
      }
      footer={
        hideActions ? null : (
          <div className="d-flex flex-1 justify-content-end">
            <DeleteFindingAction
              id={active?.id}
              originSource={active?.origin_source}
              isOnBoard={isOnBoard}
              refetchData={refetchData}
              onClose={onClose}
            />
            <Button color="cancel" className="px-0 py-1 me-4" onClick={onClose}>
              CANCEL
            </Button>
            {isOnBoard || !isOnEvent ? null : (
              <Button
                onClick={() => onSubmit('add_more')}
                color="primary"
                className="px-2 me-1"
                disabled={isSubmitting}
              >
                SAVE & ADD MORE
              </Button>
            )}
            <Button
              onClick={isOnBoard ? () => setSaveModalIsOpen(true) : onSubmit}
              color="primary"
              className="px-2"
              disabled={isSubmitting}
            >
              {isOnBoard ? 'ADD' : !isOnEvent ? 'SAVE' : 'SAVE & CLOSE'}
            </Button>
          </div>
        )
      }
      css={css`
        width: ${purchasingCaseDrawerIsOpen
          ? variables.drawerWidthSm
          : `calc(100vw - ${parseInt(variables.drawerWidthOffsetLg) - 0.5}rem)`} !important;
        z-index: ${parseInt(variables.drawerZIndex) - 1} !important;
      `}
    >
      {drawer.isOpen && !isFetching ? (
        <Body
          isOnBoard={isOnBoard}
          onTogglePurchasingCaseDrawer={onTogglePurchasingCaseDrawer}
          changeField={changeField}
          selectField={selectField}
          active={active}
          vessel={vessel}
          {...fields}
        />
      ) : null}

      <DangerousActionModal
        transparent
        action={'save'}
        onAccept={onSubmit}
        closeModal={() => setSaveModalIsOpen(false)}
        isOpen={saveModalIsOpen}
        actionText={'ADD'}
        header={'Add Finding'}
        actionHoverColor="primary"
        body={
          <div className="text-center">
            Are you sure you want to submit this <br />
            finding to Office ? Edit will be locked <br />
            onboard.
          </div>
        }
      />
    </ProfileDrawer>
  );
};

FindingForm.propTypes = {
  drawer: DrawerState.isRequired,
  auditId: PropTypes.number,
  eventAuditId: PropTypes.number,
  eventVessel: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  customNavigation: PropTypes.shape({ next_id: PropTypes.number, previos_id: PropTypes.number }),
  setActive: PropTypes.func,
  isOnBoard: PropTypes.bool,
  isOnEvent: PropTypes.bool,
  refetchData: PropTypes.func,
  active: PropTypes.object,
  navigation: PropTypes.shape({
    selected_id: PropTypes.number,
    next_id: PropTypes.number,
    previous_id: PropTypes.number
  }),
  setNavigation: PropTypes.func
};

export default FindingForm;
