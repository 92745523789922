import { useMemo, useEffect, useCallback } from 'react';

import useRandomDebounce from 'common/utils/hooks/useRandomDebounce';
import useCancelablePromise from 'common/utils/hooks/useCancelablePromise';

import { useActions } from 'utils/hooks';
import { selectListOptionsFromStore } from 'store/lists/selectors';

import * as listActions from 'store/lists/actions';
import _isObject from 'lodash/isObject';
import { useAppSelector } from '@/store/hooks';

export const transformOptions = (listing, options) => {
  switch (listing) {
    default:
      return options;
  }
};

const Status = ({ value, className = '', type, listingValueKey = 'id' }) => {
  const getListing = useCallback(() => {
    switch (type) {
      case 'events':
        return 'event-statuses';
      case 'forms':
        return 'form-statuses';
      case 'jobs':
        return 'job-statuses';
      case 'port-da-cases':
        return 'port-da-case-status';
      default:
        return 'job-statuses';
    }
  }, [type]);

  const listing = useMemo(getListing, [type, getListing]);
  const options = useAppSelector(state => selectListOptionsFromStore(state, listing));
  const modifiedOptions = useMemo(() => transformOptions(listing, options), [listing, options]);

  const cancelablePromise = useCancelablePromise();

  const [fetchListOptions] = useActions([listActions.fetchListOptions]);
  const getListOptions = async optionsLength => {
    if (!optionsLength) {
      await cancelablePromise(fetchListOptions(listing));
    }
  };

  const debouncedFetchListOptions = useRandomDebounce(getListOptions, 1, 2000);
  const status = _isObject(value)
    ? value
    : modifiedOptions?.find(el => el[listingValueKey] === value);

  useEffect(() => {
    if (!modifiedOptions?.length && !_isObject(value))
      debouncedFetchListOptions(modifiedOptions?.length);
  }, [modifiedOptions?.length]);

  return status ? (
    <span className={`view-status view-status--${status.color} ${className}`}>
      <span className="d-inline-block text-truncate">{status.name}</span>
    </span>
  ) : null;
};

export default Status;

export { default as JobStatus } from 'common/components/jobs/_base/components/Status';
