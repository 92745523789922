import { FC } from 'react';
import { FormField, SelectFieldFunc } from '@/common/types/form';
import { Department, DepartmentRole } from '@/common/types/enums';
import ShadowBox from '@/common/components/general/ShadowBox';
import DepartmentSelector from '../../selectors/departments/DepartmentSelector';
import Select from '@/ts-common/components/form/inputs/select';
import CircledButton from '@/ts-common/components/buttons/CircledButton';

type StepDynamicSetupProps = {
  isMultipleDynamic: boolean;
  isConfiguringStep: boolean;
  index: number;
  fields: {
    department_ids: FormField<number[]>;
    department_roles: FormField<DepartmentRole[]>;
  };
  eligibleDepartments: Department[];
  eligibleRoles: DepartmentRole[];
  selectField: SelectFieldFunc;
  onRemoveSubStep: () => void;
};

const StepDynamicSetup: FC<StepDynamicSetupProps> = ({
  isMultipleDynamic,
  isConfiguringStep,
  index,
  fields,
  eligibleDepartments,
  eligibleRoles,
  selectField,
  onRemoveSubStep
}) => {
  return (
    <ShadowBox flat className={`cpx-12 cpy-12 ${isConfiguringStep ? 'mb-1' : ''}`}>
      {isConfiguringStep ? (
        <div>
          {index === 0 ? (
            <div className="fs-12 fw-medium text-violet cmb-12">
              Department(s) or Role(s) that have to take action -<br /> Either one of the selected
              can fill in info.
            </div>
          ) : (
            <div className="d-flex justify-content-end cmb-20">
              <CircledButton
                type="remove"
                size={16}
                svgStyle={{ width: 8, height: 8 }}
                onClick={onRemoveSubStep}
              />
            </div>
          )}

          <DepartmentSelector
            placeholder="Select department(s)"
            onChange={(d: number[]) => selectField('department_ids')(d)}
            isMulti={true}
            className="mb-1"
            options={eligibleDepartments}
            disabled={eligibleDepartments.length === 0}
            {...fields.department_ids}
          />
          <Select
            isAsync={false}
            isClearable={true}
            isMulti={true}
            placeholder="Select role(s)"
            className="mb-0"
            memoizedRequestParams={{ path: 'lists', params: { list: 'department-roles' } }}
            getOptionValue={(option: DepartmentRole) => option.id.toString()}
            getOptionLabel={(option: DepartmentRole) => option.name}
            onChange={d => selectField('department_roles')(d)}
            options={eligibleRoles}
            disabled={eligibleRoles.length === 0}
            {...fields.department_roles}
          />
        </div>
      ) : (
        <div className="fs-12 fw-medium text-violet">
          {isMultipleDynamic ? 'Create Multiple Dynamic Step' : 'Create Dynamic Step'}
        </div>
      )}
    </ShadowBox>
  );
};

export default StepDynamicSetup;
