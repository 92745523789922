import PrioritySelector from 'common/components/selectors/PrioritySelector';
import PersonSelector from 'common/components/selectors/PersonSelector';
import findingsIcon from '@/common/assets/svg/common/findings.svg';
import FindingCategoriesSelector from '@/common/components/selectors/FindingCategoriesSelector';
import EventFindingsSelector from 'common/components/selectors/EventFindingsSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import Information from 'common/components/general/Information';
import FindingTagsSelector from '@/common/components/selectors/FindingTagsSelector';
import EventSelector from 'common/components/selectors/EventSelector';
import FormsSelector from 'common/components/selectors/FormsSelector';
import EventStatusSelector from 'common/components/selectors/EventStatusSelector';
import FormStatusSelector from 'common/components/selectors/FormStatusSelector';
import JobStatusSelector from 'common/components/selectors/JobStatusSelector';
import PurchasingRequisitionStatusesSelector from 'common/components/selectors/PurchasingRequisitionStatusSelector';
import PurchasingRequisitionSelector from 'common/components/selectors/PurchasingRequisitionSelector';
import EventAuditReasonsSelector from 'common/components/selectors/EventAuditReasonsSelector';
import EventRelatedAuditSelector from 'common/components/selectors/EventAuditsSelector';
import TopFilters from './TopFilters';

export const getColumns = () => [
  {
    header: 'I',
    filterLabel: 'Importance',
    key: 'importance_id',
    sortingKey: 'importance',
    type: 'collection',
    sort: true,
    component: PrioritySelector,
    componentRest: { isMulti: true },
    sticky: true,
    left: 0,
    headerClassName: 'z-index-3',
    bodyClassName: 'd-flex justify-content-start cpt-6  z-index-5',
    minWidth: 45,
    maxWidth: 45
  },
  {
    header: 'ID',
    key: 'id',
    data_key: 'orca_id',
    sort: true,
    sticky: true,
    truncate: true,
    left: 45,
    headerClassName: 'z-index-3',
    bodyClassName: 'd-flex justify-content-start cpt-4  z-index-5',
    minWidth: 80,
    maxWidth: 80
  },
  {
    header: 'Finding',
    key: 'finding',
    sort: true,
    sticky: true,
    truncate: false,
    left: 125,
    headerClassName: 'z-index-3',
    bodyClassName: 'd-flex justify-content-start cpt-4  border-end z-index-5',
    minWidth: 322,
    maxWidth: 322
  },
  {
    header: 'Finding',
    key: 'finding_id',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'collection',
    component: EventFindingsSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Category',
    key: 'category',
    data_key: 'category.name',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'string',
    sort: true,
    canFilter: false,
    truncate: true,
    minWidth: 145,
    maxWidth: 145
  },
  {
    header: 'Category',
    key: 'finding_category_id',
    type: 'collection',
    sort: false,
    canFilter: true,
    component: FindingCategoriesSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Department',
    key: 'department_id',
    sortingKey: 'department',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'collection',
    component: DepartmentSelector,
    componentRest: { isMulti: true },
    sort: true,
    canFilter: true,
    minWidth: 132,
    maxWidth: 132
  },
  {
    header: 'Vessel',
    key: 'vessel',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    minWidth: 139,
    maxWidth: 139,
    hiddenOnBoard: true
  },
  {
    header: 'Status',
    key: 'finding_status',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    minWidth: 125,
    maxWidth: 125
  },
  {
    header: '',
    key: 'comments',
    className: 'ps-0 d-flex justify-content-start cpt-4 ',
    sort: false,
    minWidth: 36,
    maxWidth: 36
  },
  {
    header: '',
    key: 'attachments',
    sort: false,
    className: 'me-1 d-flex justify-content-start cpt-4 ',
    minWidth: 36,
    maxWidth: 36
  },
  {
    header: 'Linked With',
    key: 'linked_with',
    columns: [
      {
        header: '',
        key: 'origin_icon',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        sort: false,
        minWidth: 32,
        maxWidth: 32,
        className: 'pe-0'
      },
      {
        header: 'Description',
        key: 'description',
        bodyClassName: 'd-flex justify-content-start cpt-4',
        sort: false,
        minWidth: 265,
        maxWidth: 265
      },
      {
        header: 'Status',
        key: 'status',
        sort: false,
        minWidth: 138,
        maxWidth: 138
      },
      {
        header: (
          <div className="d-flex align-items-center flex-nowrap">
            <div className="me-1">Date</div>

            <Information
              message={
                <div>
                  The following Dates represent:
                  <ul className="ps-2 pb-0 mb-0">
                    <li>The Job Due Date.</li>
                    <li>The Event Starting Date</li>
                    <li>The Case Submitted Date</li>
                    <li>The Form Date</li>
                  </ul>
                </div>
              }
              tooltipClassname="text-start min-width-fit max-width-412"
              svgStyle={{ height: 12, width: 12 }}
            />
          </div>
        ),
        bodyClassName: 'd-flex justify-content-start cpt-4',
        key: 'linked_with_date',
        sort: false,
        minWidth: 120,
        maxWidth: 120
      },
      {
        header: 'Audit/Inspection',
        key: 'audit',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        data_key: 'origin_audit.audit.name',
        type: 'string',
        sort: true,
        canFilter: false,
        truncate: true,
        minWidth: 223,
        maxWidth: 223
      },
      {
        header: 'Reason',
        sortingKey: 'reason',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        key: 'event_reason_id',
        data_key: 'origin_audit.reason.name',
        sort: true,
        truncate: true,
        canFilter: false,
        minWidth: 146,
        maxWidth: 146
      },
      {
        header: 'Auditing / Inspecting Company',
        key: 'company_name',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        sort: true,
        truncate: true,
        type: 'string',
        canFilter: false,
        minWidth: 226,
        maxWidth: 226
      },
      {
        header: 'Auditor / Inspector',
        key: 'auditor_name',
        bodyClassName: 'd-flex justify-content-start cpt-4 ',
        sort: true,
        truncate: true,
        type: 'string',
        canFilter: false,
        minWidth: 192,
        maxWidth: 192
      }
    ]
  },
  {
    header: 'Subcategory',
    key: 'subcategory',
    data_key: 'subcategory.name',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    canFilter: false,
    truncate: true,
    minWidth: 220,
    maxWidth: 220
  },
  {
    header: 'Tags',
    key: 'tag_id',
    sortingKey: 'tags',
    bodyClassName: 'd-flex justify-content-start cpt-4',
    type: 'collection',
    component: FindingTagsSelector,
    componentRest: { isMulti: true },
    sort: false,
    minWidth: 161,
    maxWidth: 161
  },
  {
    header: 'Date',
    key: 'created_at',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    type: 'date',
    canFilter: false,
    minWidth: 105,
    maxWidth: 105
  },
  {
    header: (
      <div className="d-flex align-items-center flex-nowrap">
        <div>Days In</div>
        <Information
          message={
            <div>
              Here we count:
              <ul className="ps-2 pb-0 mb-0">
                <li>Days since Reported date for unresolved findings.</li>
                <li>Days elapsed between Reported and resolution dates.</li>
              </ul>
            </div>
          }
          tooltipClassname="text-start min-width-fit max-width-412"
          className="ms-1"
          svgStyle={{ height: 12, width: 12 }}
        />
      </div>
    ),
    key: 'days_unresolved_column',
    sortingKey: 'days_unresolved',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    sort: true,
    minWidth: 120,
    maxWidth: 120
  },
  {
    header: 'Due Date',
    key: 'finding_due_date',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'date',
    sort: true,
    minWidth: 100,
    maxWidth: 100
  },
  {
    header: 'Resolution Date',
    key: 'resolved_at',
    bodyClassName: 'd-flex justify-content-start cpt-4 ',
    type: 'date',
    sort: true,
    minWidth: 140,
    maxWidth: 140
  },
  {
    header: '',
    key: 'evaluations',
    className: 'd-flex justify-content-start cpt-4',
    minWidth: 60,
    maxWidth: 60
  },
  {
    header: 'Reported in Events',
    key: 'event_id',
    type: 'collection',
    component: EventSelector,
    componentRest: { isMulti: true, listParams: { modules: ['audits'] } },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Created in form',
    key: 'form_uid',
    type: 'collection',
    component: FormsSelector,
    componentRest: { isMulti: true },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Reported by',
    key: 'auditor_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true },
    canFilter: true,
    hidden: true
  },
  {
    header: 'Linked with Event',
    key: 'with_origin_audit',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Event Starting Date',
    key: 'event_starting_date',
    type: 'date',
    hidden: true
  },
  {
    header: 'Form Date',
    key: 'form_date',
    type: 'date',
    hidden: true
  },
  {
    header: 'Jobs Due Date',
    key: 'job_due_date',
    type: 'date',
    hidden: true
  },
  {
    header: 'Event status',
    key: 'event_status_id',
    type: 'collection',
    component: EventStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Job status',
    key: 'job_status_id',
    type: 'collection',
    component: JobStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Form status',
    key: 'form_status_id',
    type: 'collection',
    component: FormStatusSelector,
    componentRest: { isMulti: true },
    hidden: true
  },
  {
    header: 'Linked with Form',
    key: 'with_form_submissions',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Reason',
    key: 'event_reason_id',
    type: 'collection',
    component: EventAuditReasonsSelector,
    componentRest: { isMulti: true },
    sort: false,
    hidden: true,
    truncate: true,
    minWidth: 146,
    maxWidth: 146
  },
  {
    header: 'Days Unresolved',
    key: 'days_unresolved',
    canFilter: true,
    type: 'number',
    hidden: true
  },
  {
    header: 'Audit/Inspector - Audit module',
    key: 'audit_inspector_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Audit/Inspector Company - Audit module',
    key: 'audit_company_inspector_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { type: 'company' } },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Audit/Inspection',
    key: 'event_audit_id',
    type: 'collection',
    component: EventRelatedAuditSelector,
    componentRest: { isMulti: true },
    sort: false,
    canFilter: true,
    hidden: true
  },
  {
    header: 'Linked with Purchase Case',
    key: 'with_purchasing_cases',
    type: 'boolean',
    hidden: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Purchase Case Status',
    key: 'purchasing_status_id',
    type: 'collection',
    canFilter: true,
    hidden: true,
    component: PurchasingRequisitionStatusesSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Purchase Case Submitted Date',
    key: 'purchasing_submitted_at',
    type: 'date',
    canFilter: true,
    hidden: true
  },
  {
    header: 'Purchase Case',
    key: 'purchasing_requisition_id',
    type: 'collection',
    canFilter: true,
    hidden: true,
    component: PurchasingRequisitionSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Linked with jobs',
    key: 'with_jobs',
    type: 'boolean',
    hidden: true,
    canFilter: true,
    options: [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' }
    ]
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

const getTopFilters = () => [
  {
    name: 'vessel_id',
    operation: 'oneOf',
    value: null
  },
  {
    name: 'finding_subcategory_id',
    operation: 'oneOf',
    value: null
  },
  {
    name: 'resolved',
    operation: '=',
    value: null
  }
];

const config = {
  getColumns,
  getTopFilters,
  isNewTable: false,
  topFiltersComponent: TopFilters,
  icon: findingsIcon,
  tableStyle: 'default',
  isModular: true,
  hideTableFilters: false,
  hideTableReorder: false,
  pageTitleComponent: null,
  name: 'Findings',
  entity: 'jobs-findings'
};

export default config;
