import PersonSelector from 'common/components/selectors/PersonSelector';
import LoadingSituationSelector from 'common/components/selectors/LoadingSituationSelector';
import VesselGroupSelector from 'common/components/selectors/VesselGroupSelector';
import CaptainReportsStatusSelector from 'common/components/selectors/CaptainReportsStatusSelector';

import runningHours from 'common/assets/svg/common/reminder.svg';

export const getColumns = () => [
  {
    header: 'Vessel',
    key: 'vessel_name',
    maxWidth: 200,
    minWidth: 200,
    hiddenOnBoard: true,
    sort: true
  },
  {
    header: 'Vessel Groups',
    key: 'vessel_groups',
    type: 'collection',
    component: VesselGroupSelector,
    componentRest: { isMulti: true },
    hidden: true,
    hiddenOnBoard: true
  },
  {
    header: 'Status',
    sort: true,
    key: 'status',
    type: 'collection',
    component: CaptainReportsStatusSelector,
    componentRest: { isMulti: true },
    minWidth: 90,
    maxWidth: 90
  },
  {
    header: 'Local Date & time',
    key: 'local_timestamp',
    type: 'datetime',
    sort: true,
    showUtc: true,
    maxWidth: 160,
    minWidth: 160
  },
  {
    header: 'UTC Date & time',
    key: 'timestamp',
    type: 'datetime',
    sort: true,
    showUtc: true,
    maxWidth: 160,
    minWidth: 160
  },
  {
    header: 'Approved By',
    key: 'approved_by_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { can_login: true } },
    sort: false,
    hidden: true,
    hiddenOnBoard: true
  },
  {
    header: 'Approved By',
    key: 'approved_by',
    type: 'collection',
    canFilter: false,
    sort: true,
    maxWidth: 280,
    minWidth: 280
  },
  {
    header: 'Approved At',
    key: 'approved_at',
    type: 'datetime',
    sort: true,
    maxWidth: 160,
    minWidth: 160
  },
  {
    header: 'Reviewed By',
    key: 'reviewed_by_tech_id',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { can_login: true } },
    sort: false,
    hidden: true,
    maxWidth: 160,
    minWidth: 160,
    hiddenOnBoard: true
  },
  {
    header: 'Reviewed By',
    key: 'reviewed_by_tech',
    type: 'collection',
    canFilter: false,
    sort: true,
    maxWidth: 280,
    minWidth: 280
  },
  {
    header: 'Reviewed At',
    key: 'reviewed_by_tech_at',
    type: 'datetime',
    sort: true,
    maxWidth: 160,
    minWidth: 160
  },
  {
    header: 'Situation',
    key: 'loading_condition',
    component: LoadingSituationSelector,
    componentRest: { isMulti: true },
    className: 'text-center',
    type: 'collection',
    maxWidth: 184,
    minWidth: 184
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

const config = {
  getColumns,
  isNewTable: false,
  getTopFilters: () => null,
  topFiltersComponent: () => null,
  tableStyle: 'default',
  icon: runningHours,
  isModular: false,
  hideTableFilters: false,
  hideTableReorder: false,
  pageTitleComponent: null,
  name: 'Running Hours',
  entity: 'pms-running-hours'
};

export default config;
