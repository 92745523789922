import { useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import ActivateButton from 'common/components/buttons/ActivateButton';

const RejectButton = ({
  modalHandler,
  isDisabled,
  canReject,
  currentStepIndex,
  steps,
  ...args
}) => {
  const [dropdownOpened, setDropdownOpened] = useState(false);

  const toggle = () => {
    setDropdownOpened(prev => !prev);
  };

  if (!canReject) return null;

  return (
    <div className="d-flex align-items-center justify-content-end text-end me-1 position-relative">
      <Dropdown isOpen={dropdownOpened && !isDisabled} toggle={toggle} direction="up">
        <DropdownToggle data-toggle="dropdown" tag="div">
          <ActivateButton color="red" disabled={isDisabled}>
            Reject
          </ActivateButton>
        </DropdownToggle>

        <DropdownMenu {...args}>
          {currentStepIndex === 0 ? (
            <DropdownItem
              onClick={() =>
                modalHandler({
                  isOpen: true,
                  type: 'reject',
                  extraParams: { start_from_step_id: steps[0]?.id, isFirstStep: true }
                })
              }
            >
              Reject and start from the beginning of the Process
            </DropdownItem>
          ) : null}
          {currentStepIndex > 0
            ? steps
                .filter((_, index) => index < currentStepIndex)
                .map((step, index) => (
                  <>
                    <DropdownItem
                      onClick={() =>
                        modalHandler({
                          isOpen: true,
                          type: 'reject',
                          extraParams: {
                            start_from_step_id: step.id,
                            start_from_step_index: index + 1,
                            isFirstStep: false
                          }
                        })
                      }
                    >
                      Reject and start from the step {index + 1}
                    </DropdownItem>
                  </>
                ))
            : null}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default RejectButton;

RejectButton.propTypes = {
  modalHandler: PropTypes.func,
  isDisabled: PropTypes.bool,
  currentStepIndex: PropTypes.number,
  steps: PropTypes.array
};
