import { useState } from 'react';
import { FC } from 'react';

import Input, { InputProps } from '@/ts-common/components/form/inputs/Input';
import SvgRender from 'common/components/general/SvgRender';
import Spinner from '@/ts-common/components/general/Spinner';
import useUpdateEffect from 'common/utils/hooks/useUpdateEffect';

import searchSvg from 'common/assets/svg/common/search.svg';
import close from 'common/assets/svg/actions/close.svg';

type SearchInputProps = {
  className?: string;
  wrapperClassName?: string;
  initialValue?: string;
  inputProps?: InputProps;
  showClearButton: boolean;
  onChange: (search: string) => void;
  onClear?: () => void;
};

const SearchInput: FC<SearchInputProps> = ({
  className = '',
  wrapperClassName = '',
  initialValue,
  inputProps = {},
  showClearButton = false,
  onChange,
  onClear
}) => {
  const [textFieldValue, setTextFieldValue] = useState<string>(initialValue || '');
  const [isSearching, setIsSearching] = useState(false);

  const onSearch = async (search: string) => {
    setIsSearching(true);

    await onChange(search);

    setIsSearching(false);
  };

  useUpdateEffect(() => {
    setTextFieldValue(prev => (prev !== initialValue ? initialValue || '' : prev));
  }, [initialValue]); // Only call effect if debounced search term changes

  const onClearButtonClick = () => {
    setTextFieldValue('');
    if (onClear) {
      onClear();
    }
  };

  return (
    <div className={`styled-search-input ${className}`}>
      <div className={`search-input-wrapper d-flex align-items-center ${wrapperClassName}`}>
        <SvgRender src={searchSvg} style={{ height: 14, width: 14 }} />
        <Input
          data-testid="search-input__input"
          className="w-100p"
          placeholder="Search"
          value={textFieldValue}
          onChange={e => setTextFieldValue(e.target.value)}
          onAutoSave={onSearch}
          {...inputProps}
        />

        {isSearching ? (
          <Spinner size={22} className={'me-1'} />
        ) : showClearButton ? (
          <SvgRender
            data-testid="search-input__clear"
            className="search-input-wrapper__clear"
            src={close}
            style={{ height: 10, width: 10 }}
            onClick={onClearButtonClick}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SearchInput;
