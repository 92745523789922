import { getAuthorizedPermissionSettings, isAuthorized } from 'utils/permissions/authorize';

export const originAvoidLockKeys = [
  'port_document_action_document',
  'manual',
  'review_process_substep_form',
  'event'
];

export const getFormID = ({ formSubmissionId, initialFormId, paramsFormId, searchFormId }) => {
  if (formSubmissionId) return [formSubmissionId, true];
  if (initialFormId) return [initialFormId, false];

  return searchFormId ? [searchFormId, true] : [paramsFormId, true];
};

export const getRemarksCounter = (
  forCorrectionRemarks,
  reviewRemarks,
  reviewRemarksActionable,
  forCorrectionRemarksActionable,
  isOnboard,
  isForRegularRemarks
) => {
  let sum = 0;

  if (isForRegularRemarks) {
    if (reviewRemarks && !reviewRemarksActionable && !isOnboard) sum++;
    if (forCorrectionRemarks && !forCorrectionRemarksActionable) sum++;
  } else {
    if (reviewRemarksActionable && reviewRemarks) sum++;
    if (forCorrectionRemarksActionable && forCorrectionRemarks) sum++;
  }

  return sum;
};

export const isFormAllowed = (account, permission, currentId) => {
  if (!permission || Array.isArray(permission)) return false;

  const isAllowed = isAuthorized(account, [permission]);

  if (!isAllowed) return false;

  const forms = getAuthorizedPermissionSettings(account, permission)?.forms;

  if (!forms?.length) {
    return true;
  }

  return forms.includes(currentId);
};
