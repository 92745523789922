import { GroupBase, StylesConfig } from 'react-select';

import variables from '@/ts-common/assets/scss/abstracts/_exports.module.scss';
import { isEntityLabeled } from './select-helpers';

type StylesParamsType = {
  size?: 'sm' | 'lg';
  error?: string | null;
};

export const styles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: StylesParamsType
): StylesConfig<Option, IsMulti, Group> => {
  const size = props.size || 'sm';
  const error = props.error || '';

  return {
    container: base => ({
      ...base,
      width: '100%'
    }),
    control: (_, state) => ({
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      overflow: 'hidden',
      borderRadius: variables.borderRadiusBase,
      minHeight: `${size === 'sm' ? variables.inputHeightSm : variables.inputHeightLg}`,
      maxHeight: `${size === 'sm' ? variables.inputHeightSm : variables.inputHeightLg}`,
      border: `1px solid ${
        error
          ? variables.red
          : state.isDisabled
            ? `${variables.inputDisabledBorderColor} !important`
            : state.isFocused
              ? variables.inputFocusBorderColor
              : variables.inputBorderColor
      }`,
      ['&.react-select__control--menu-is-open']: {
        ['.react-select__dropdown-indicator']: {
          transform: 'rotate(180deg)'
        }
      },
      ['&:hover']: {
        border: `1px solid ${
          state.isFocused ? variables.inputFocusBorderColor : variables.inputHoverBorderColor
        }`
      }
    }),
    indicatorsContainer: (provided, state) => {
      const styles = { ...provided };
      if (
        !state.isMulti &&
        !state.selectProps.dynamicWidth &&
        isEntityLabeled(state) &&
        !state.isDisabled
      ) {
        styles.flex = 1;
      }
      return styles;
    },
    dropdownIndicator: (provided, state) => {
      const styles = {
        ...provided,
        color: variables.bodyColor,
        padding: 0,
        margin: `0 0.5rem 0 0`,
        display: state.isDisabled ? 'none' : 'inline-flex',
        alignItems: 'center'
      };
      if (!state.isMulti && isEntityLabeled(state)) {
        styles.marginLeft = state.selectProps.dynamicWidth ? variables.size8 : 'auto';
      }
      return styles;
    },
    indicatorSeparator: () => ({
      display: 'none'
    }),
    clearIndicator: (provided, state) => {
      const styles = {
        ...provided,
        padding: 0,
        width: '14px',
        height: '14px',
        alignItems: 'center',
        marginRight: '0.25rem',
        cursor: 'pointer',
        ['&:hover']: {
          color: variables.red
        }
      };
      if (!state.isMulti && isEntityLabeled(state)) {
        styles.marginLeft = -18;
        styles.zIndex = 1;
        styles.color = variables.primary;
      }
      return styles;
    },
    placeholder: (provided, _, styles = {}) => ({
      ...provided,
      fontWeight: variables.inputPlaceholderFontWeight,
      fontSize: variables.inputsFontSize,
      color: variables.inputPlaceholderColor,
      marginLeft: 0,

      ...styles
    }),
    valueContainer: (provided, state) => {
      const isForEntity = !state.isMulti && !state.isDisabled && isEntityLabeled(state);

      const wrap: 'wrap' | 'nowrap' = isForEntity ? 'nowrap' : 'wrap';

      const styles = {
        ...provided,
        display: 'flex',
        flexWrap: wrap,
        height: 20,
        justifyContent: 'flex-start !important',
        flexDirection: 'row' as const,
        flex: '1',
        padding: `0 0.5rem 0 ${variables.inputPaddingX}`
      };
      if (isForEntity) {
        styles.flex = '0 0 auto';
        styles.width = 'auto';
        styles.maxWidth = 'calc(100% - 18px)';
        styles.paddingRight = '0';
        if (state.selectProps.dynamicWidth) {
          styles.width = 'min-content';
          styles.maxWidth = 'min-content';
          styles.flex = '1';
          styles.flexWrap = wrap;
        }
      }
      return styles;
    },
    singleValue: (provided, state) => {
      const { position, top, transform, ...rest } = provided;

      const styles = {
        ...rest,
        fontWeight: variables.inputsFontWeight,
        fontSize: variables.inputsFontSize,
        lineHeight: 1.1,
        color: variables.primary,
        marginLeft: 0,
        maxWidth: '100%'
      };
      if (!state.isMulti && !state.isDisabled && isEntityLabeled(state)) {
        styles.marginRight = 0;
      }
      return styles;
    },
    multiValue: (provided, state) => {
      const styles = {
        ...provided,
        backgroundColor: 'rgba(112,132,211,0.1)',
        borderRadius: 3,
        margin: 0,
        padding: `0 ${variables.size2}`,
        minHeight: variables.size20,
        fontSize: variables.inputsFontSize,
        alignItems: 'center',
        display: 'flex',
        position: 'relative' as const
      };
      if (state.isMulti && isEntityLabeled(state)) {
        styles.backgroundColor = 'transparent';
      }
      return styles;
    },
    multiValueLabel: provided => ({
      ...provided,
      color: variables.primary,
      padding: 0,
      paddingLeft: 0,
      fontWeight: variables.inputsFontWeight,
      fontSize: variables.inputsFontSize,
      lineHeight: 1.1
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      paddingRight: 0,
      background: 'none !important',
      display: state.isDisabled ? 'none' : 'flex',
      color: variables.primary,
      ['&:hover']: {
        color: variables.red
      }
    }),
    menu: (provided, _, styles = {}) => ({
      ...provided,
      marginTop: 4,
      marginBottom: 0,
      borderRadius: 3,
      boxShadow: '0 2px 10px 0 rgba(0,0,0,0.2)',
      border: 'none',
      borderTop: 0,
      backgroundColor: '#fff',
      width: '100%',
      zIndex: 100,
      ...styles
    }),
    menuList: provided => ({
      ...provided,
      overflowX: 'hidden'
    }),
    option: (provided, state, styles = {}) => ({
      ...provided,
      padding: `${variables.size4} ${variables.size12}`,
      fontWeight: 400,
      backgroundColor: state.isSelected && !state.isMulti ? variables.bodyBg : '',
      color: variables.primary,
      lineHeight: '15px',
      fontSize: variables.inputsFontSize,

      ['&:not(:last-child)']: {
        marginBottom: 4
      },

      ['&:active']: {
        backgroundColor: variables.bodyBg
      },
      ['&:hover']: {
        backgroundColor: variables.bodyBg
      },

      ...styles
    }),
    input: provided => ({
      ...provided,
      color: variables.primary,
      marginTop: 0,
      marginBottom: 0,
      fontSize: variables.inputsFontSize,
      padding: 0,
      position: 'absolute',
      left: variables.size8,
      fontWeight: variables.fontWeightMedium
    })
  };
};
