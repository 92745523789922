export const imagesConfig = {
  plugins: [
    'AutoImage',
    'Image',
    'ImageCaption',
    'ImageInsert',
    'ImageResize',
    'ImageStyle',
    'ImageToolbar',
    'ImageUpload',
    'LinkImage'
  ],
  toolbar: ['|', 'imageUpload']
  // toolbar: ['|', 'imageUpload', 'imageInsert']
};

export const tablesConfig = {
  contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
};

export const pluginConfig = [
  'Alignment',
  'AutoImage',
  'Bold',
  'Essentials',
  'FontColor',
  'FontSize',
  'Heading',
  'Indent',
  'IndentBlock',
  'Italic',
  'Link',
  'List',
  'ListProperties',
  'PageBreak',
  'Paragraph',
  'PasteFromOffice',
  'RemoveFormat',
  'SpecialCharacters',
  'SpecialCharactersEssentials',
  'SpecialCharactersMathematical',
  'Strikethrough',
  'Table',
  'TableCaption',
  'TableCellProperties',
  'TableColumnResize',
  'TableProperties',
  'TableToolbar',
  'TextTransformation',
  'TodoList',
  'Underline',
  'Undo'
];

export const toolbarConfig = [
  'fontSize',
  'fontColor',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  '|',
  'alignment',
  'RemoveFormat',
  '|',
  'numberedList',
  'bulletedList',
  'toDoList',
  '|',
  'indent',
  'outdent',
  '|',
  'link',
  'insertTable',
  '|',
  'pageBreak',
  '|',
  'undo',
  'redo'
];

export const minimalToolbarConfig = [
  'bold',
  'italic',
  'underline',
  'bulletedList',
  'numberedList',
  'indent',
  'outdent',
  'insertTable',
  'link',
  'alignment',
  'undo',
  'redo'
];

export const fontColor = {
  colors: [
    {
      color: '#000',
      label: 'Black'
    },
    {
      color: '#ffffff',
      label: 'White'
    },
    {
      color: '#cd1e3d',
      label: 'Crimson'
    },
    {
      color: '#354069',
      label: 'Nautica'
    },
    {
      color: '#39628d',
      label: 'Deep Sea'
    },
    {
      color: '#99d9d9',
      label: 'Aqua'
    },
    {
      color: '#808080',
      label: 'Gray'
    },
    {
      color: '#22242a',
      label: 'Squid Link'
    },
    {
      color: '#fedd00',
      label: 'Butter Yellow'
    }
  ]
};

export const fontBackgroundColor = {
  colors: [
    {
      color: '#000',
      label: 'Black'
    },
    {
      color: '#ffffff',
      label: 'White'
    },
    {
      color: '#cd1e3d',
      label: 'Crimson'
    },
    {
      color: '#354069',
      label: 'Nautica'
    },
    {
      color: '#39628d',
      label: 'Deep Sea'
    },
    {
      color: '#99d9d9',
      label: 'Aqua'
    },
    {
      color: '#808080',
      label: 'Gray'
    },
    {
      color: '#22242a',
      label: 'Squid Link'
    },
    {
      color: '#fedd00',
      label: 'Butter Yellow'
    }
  ]
};
