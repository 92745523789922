import { FormStatus, FormSubmission } from '@/common/types/form-submissions';

export const shouldShowCloseConfirmation = (
  isDrawerClosing: boolean,
  isOnboard: boolean,
  statusProgress: string,
  statusEditSide: string,
  hasPreviousStatus: boolean,
  hasFormSubmission: boolean
) => {
  if (!isDrawerClosing || isOnboard || hasPreviousStatus) return false;

  if (
    statusProgress &&
    statusProgress !== 'reviewed' &&
    statusEditSide === 'office' &&
    hasFormSubmission
  )
    return true;

  return false;
};

export const getIsFormSubmitted = (formSubmission?: FormSubmission) => {
  if (!formSubmission) return false;

  const { status } = formSubmission;

  return !!status;
};

export const getIsFormUnlockedBasedOnStatus = (
  status: FormStatus | undefined,
  formSubmission: FormSubmission,
  isOnboard: boolean
) => {
  const isSubmitted = getIsFormSubmitted(formSubmission);

  if (!status || !isSubmitted) return true;

  const { edit_side } = status;

  return isOnboard ? edit_side === 'vessel' : edit_side === 'office';
};

export const getSubmittedFormCanBeDeleted = (
  status: FormStatus,
  isForVessel: boolean,
  isOnboard: boolean
) => {
  const { edit_side } = status;

  // Vessel Forms can be deleted only based on the status edit_side - other locking mechanism may be applied based on permissions)
  if (isOnboard) {
    return edit_side === 'vessel';
  } else if (isForVessel) {
    return edit_side === 'office';
  }

  // Office Forms can be deleted at any status (other locking mechanism may be applied based on permissions)
  return true;
};
