import {
  PurchasingRequisitionItemCommentsQueryParams,
  GetItemDetailsParams
} from '@/common/types/purchasing';
import {
  comparePurchasingRequisitionItems,
  ComparePurchasingRequisitionItemsPerams,
  getPurchasingRequisitionAccountBudgets,
  GetPurchasingRequisitionAccountBudgetsParams,
  getPurchasingRequisitionCategoriesTotals,
  GetPurchasingRequisitionCategoriesTotalsParams,
  getPurchasingRequisitionItemComments,
  getPurchasingRequisitionItemsSupplierTotals,
  GetPurchasingRequisitionItemsSupplierTotalsParams,
  getSparePartItemDetails,
  getStoreItemDetails,
  getPurchasingRequisitionAllSupplierTotals,
  getPurchasingRequisitionAllSupplierTotalsParams
} from './api';
import {
  PURCHASING_REQUISITION_ACCOUNT_BUDGETS,
  PURCHASING_REQUISITION_ITEM_COMMENTS,
  PURCHASING_REQUISITION_ITEMS_COMPARISON,
  PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS,
  PURCHASING_REQUISITION_ALL_SUPPLIER_TOTALS,
  PURCHASING_REQUISITION_SPARE_PARTS_ITEM_DETAILS,
  PURCHASING_REQUISITION_STORE_ITEM_DETAILS
} from './query-keys';
import { useQuery } from '@tanstack/react-query';
import queryClient from '@/utils/query';
import _sortBy from 'lodash/sortBy';

/* Comments */
export const useGetPurchasingRequisitionItemComments = ({
  id
}: PurchasingRequisitionItemCommentsQueryParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_ITEM_COMMENTS],
    queryFn: () => getPurchasingRequisitionItemComments({ id }),
    enabled: !!id,
    retry: false
  });
};

export const useGetStoreItemDetails = ({ id }: GetItemDetailsParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_STORE_ITEM_DETAILS],
    queryFn: () => getStoreItemDetails({ id }),
    enabled: !!id,
    retry: false
  });
};

export const useGetSparePartsItemDetails = ({ id }: GetItemDetailsParams) => {
  return useQuery({
    queryKey: [id, PURCHASING_REQUISITION_SPARE_PARTS_ITEM_DETAILS],
    queryFn: () => getSparePartItemDetails({ id }),
    enabled: !!id,
    retry: false,
    select: data => {
      const {
        attachments,
        comments,
        description,
        part_no,
        part_position_no,
        drawing_no,
        drawing_file,
        vessel_system
      } = data;

      // How to select system & subsystem from the vessel_system
      // When we have parent_id then the system is the parent and the subsystem is the vessel_system
      // When we don't have parent_id then the system is the vessel_system and the subsystem is the children
      return {
        spare_part: {
          attachments,
          comments,
          description,
          part_no,
          drawing_file,
          part_position_no,
          drawing_no
        },
        system: vessel_system.parent_id ? vessel_system.parent : vessel_system,
        subsystem: vessel_system.parent_id
          ? vessel_system
          : vessel_system.children.length
          ? vessel_system.children[0]
          : null
      };
    }
  });
};
/* -- */

/* Purchasing Categories */
export const useGetPurchasingRequisitionCategoriesTotals = (
  params: GetPurchasingRequisitionCategoriesTotalsParams & { enabled: boolean }
) => {
  const { enabled, ...rest } = params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS, rest],
    queryFn: () => getPurchasingRequisitionCategoriesTotals(rest),
    enabled,
    retry: false,
    select: response => _sortBy(response, 'name')
  });
};
/* -- */

/* Comparison */
export const useComparePurchasingRequisitionItems = (
  params: ComparePurchasingRequisitionItemsPerams & {
    enabled: boolean;
    requisition_item_id?: number;
    requisition_supplier_id?: number | string;
  }
) => {
  const { enabled, requisition_item_id, requisition_supplier_id, id, category_id, ...rest } =
    params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_COMPARISON, id, category_id, rest],
    queryFn: () => comparePurchasingRequisitionItems({ id, category_id, ...rest }),
    enabled,
    retry: false,
    select: response => {
      if (requisition_item_id || requisition_supplier_id) {
        const itemData = response.data.find(i => i.id === requisition_item_id);
        const { supplier_details, ...base } = itemData || {};

        return {
          item: base,
          comparison_details: requisition_supplier_id
            ? supplier_details?.find(s => +s.requisition_supplier_id === +requisition_supplier_id)
                ?.comparison_details
            : null,
          item_optimum_totals: requisition_item_id
            ? response.item_optimum_totals.find(
                i => +i.requisition_item_id === +requisition_item_id
              )
            : null,
          totals: requisition_supplier_id
            ? response.totals.find(s => +s.requisition_supplier_id === +requisition_supplier_id)
            : null
        };
      }

      const { optimum_total_sum, proposed_sum, approved_sum } = response;

      return { optimum_total_sum, proposed_sum, approved_sum };
    }
  });
};

export const recomparePurchasingRequisitionItems = (id: number, category_id?: number) =>
  queryClient.invalidateQueries({
    queryKey: category_id
      ? [PURCHASING_REQUISITION_ITEMS_COMPARISON, id, category_id]
      : [PURCHASING_REQUISITION_ITEMS_COMPARISON, id]
  });

/* -- */

/* Supplier Totals */
export const useGetPurchasingRequisitionSupplierTotals = (
  params: getPurchasingRequisitionAllSupplierTotalsParams
) => {
  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ALL_SUPPLIER_TOTALS, params],
    queryFn: () => getPurchasingRequisitionAllSupplierTotals(params),
    enabled: !!params.id,
    retry: false,
    refetchOnWindowFocus: false
  });
};

export const recalculatePurchasingRequisitionSupplierTotals = () =>
  queryClient.invalidateQueries({
    queryKey: [PURCHASING_REQUISITION_ALL_SUPPLIER_TOTALS]
  });

export const useGetPurchasingRequisitionItemsSupplierTotals = (
  params: GetPurchasingRequisitionItemsSupplierTotalsParams
) => {
  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS, params],
    queryFn: () => getPurchasingRequisitionItemsSupplierTotals(params),
    enabled: !!params.id,
    retry: false
  });
};

export const recalculatePurchasingRequisitionItemsSupplierTotals = () =>
  queryClient.invalidateQueries({
    queryKey: [PURCHASING_REQUISITION_ITEMS_SUPPLIER_TOTALS]
  });
/* -- */

/* Account Bugdet */
export const useGetPurchasingRequisitionAccountBudgets = (
  params: GetPurchasingRequisitionAccountBudgetsParams & { enabled: boolean }
) => {
  const { id, enabled } = params;

  return useQuery({
    queryKey: [PURCHASING_REQUISITION_ACCOUNT_BUDGETS, id],
    queryFn: () => getPurchasingRequisitionAccountBudgets({ id }),
    enabled,
    retry: false,
    refetchOnWindowFocus: false
  });
};
/* -- */
