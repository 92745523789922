import PropTypes from 'prop-types';
import TabsModule from 'common/components/general/TabsModule';
import LinkEntities from 'common/components/link-entities';
import { useEffect, useState } from 'react';

import job from 'common/assets/svg/common/jobs.svg';
import form from 'common/assets/svg/common/forms.svg';
import purchasing from 'common/assets/svg/common/purchasing.svg';

const LinkEntitiesWithTabs = ({
  jobsState = {},
  formsState = {},
  purchasingCasesState = {},
  ...rest
}) => {
  const [activeTabs, setActiveTabs] = useState({});

  const tabs = [
    {
      label: 'jobs',
      icon: job,
      name: 'Link Jobs',
      visible: !!jobsState,
      active: activeTabs?.jobs
    },
    {
      label: 'forms',
      icon: form,
      name: 'Link Forms',
      visible: !!formsState,
      active: activeTabs?.forms
    },
    {
      label: 'purchasing_cases',
      icon: purchasing,
      name: 'Link Purchasing Cases',
      visible: !!purchasingCasesState,
      active: activeTabs?.purchasing_cases
    }
  ];

  useEffect(() => {
    if (jobsState?.linkedJobs?.length && !activeTabs?.jobs)
      setActiveTabs(prev => ({ ...prev, jobs: true }));
  }, [activeTabs?.jobs, jobsState?.linkedJobs?.length]);

  useEffect(() => {
    if (formsState?.linkedForms?.length && !activeTabs?.forms)
      setActiveTabs(prev => ({ ...prev, forms: true }));
  }, [activeTabs?.forms, formsState?.linkedForms?.length]);

  useEffect(() => {
    if (purchasingCasesState?.linkedPurchasingCases?.length && !activeTabs?.purchasing_cases)
      setActiveTabs(prev => ({ ...prev, purchasing_cases: true }));
  }, [activeTabs?.purchasing_cases, purchasingCasesState?.linkedPurchasingCases?.length]);

  const cancelTabAction = label => {
    setActiveTabs(prev => ({ ...prev, [label]: false }));
  };

  return (
    <>
      <TabsModule
        tabs={tabs}
        className="mb-4"
        onTabClick={label => setActiveTabs(prev => ({ ...prev, [label]: true }))}
      />
      <LinkEntities
        jobsState={activeTabs?.jobs ? { ...jobsState, tabLabel: 'jobs' } : null}
        jobsPrefillState={jobsState?.prefillState ? jobsState.prefillState : null}
        formsState={activeTabs?.forms ? { ...formsState, tabLabel: 'forms' } : null}
        purchasingCasesState={
          activeTabs?.purchasing_cases
            ? { ...purchasingCasesState, tabLabel: 'purchasing_cases' }
            : null
        }
        cancelTabAction={cancelTabAction}
        {...rest}
      />
    </>
  );
};

LinkEntitiesWithTabs.propTypes = {
  jobsState: PropTypes.object,
  formsState: PropTypes.object,
  purchasingCasesState: PropTypes.object
};

export default LinkEntitiesWithTabs;
