import PropTypes from 'prop-types';

const PALETTE_SIZE = 9; // $label-palette size constant

const ColoredLabel = ({
  id,
  color = 'primary',
  text,
  className = 'fw-bold text-uppercase',
  inverted,
  index,
  withShadow,
  flatOnHover = true,
  ...rest
}) => {
  const getColorClassNames = () => {
    if (index >= 0) {
      return `colored-label__dynamic-color_${index % PALETTE_SIZE}`;
    }

    if (inverted) {
      return `colored-label__${color}-inverted`;
    }

    return `colored-label__${color}`;
  };

  return (
    <div
      id={id}
      className={`colored-label d-flex align-items-center justify-content-center ${
        withShadow ? 'colored-label--shadowed' : ''
      } ${
        withShadow && flatOnHover ? 'colored-label--shadowed-hovered' : ''
      } ${getColorClassNames()} ${className}`}
      {...rest}
    >
      {text}
    </div>
  );
};

ColoredLabel.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.any, //supports any theme-color
  className: PropTypes.string,
  inverted: PropTypes.bool,
  index: PropTypes.number,
  withShadow: PropTypes.bool,
  flatOnHover: PropTypes.bool // Remove shadow on hover
};

export default ColoredLabel;
