import { FC, ReactNode } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { numberToStr } from '@/ts-common/utils/numbers';
import FormFieldPreviewer from '@/ts-common/components/form/helpers/FormFieldPreviewer';
import FormGroupWrap from '@/ts-common/components/form/helpers/FormGroupWrap';
import useAutoSaveInputValue from '@/ts-common/utils/hooks/useAutoSaveInputValue';

interface NumberInputProps extends NumericFormatProps {
  error?: string | null;
  className?: string;
  innerClassName?: string;
  name?: string;
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  invisible?: boolean;
  viewOnly?: boolean;
  allowUnlimitedDecimalScale?: boolean;
  dataCy?: string;
  onAutoSave?: (value: number | null) => Promise<unknown>;
}

const NumberInput: FC<NumberInputProps> = ({
  error,
  className,
  name,
  value,
  onChange,
  onAutoSave = () => new Promise(resolve => resolve(null)),
  onBlur,
  label,
  placeholder,
  maxLength,
  disabled,
  decimalScale,
  invisible,
  innerClassName = '',
  viewOnly,
  thousandSeparator = ',',
  decimalSeparator = '.',
  allowUnlimitedDecimalScale = false,
  dataCy,
  ...rest
}) => {
  const { onAutoSave: autoSaveValue } = useAutoSaveInputValue<number | null>(onAutoSave);

  if (viewOnly)
    return (
      <FormFieldPreviewer
        className={className}
        label={label}
        value={numberToStr(value, decimalScale)}
        type="number"
      />
    );

  return (
    <FormGroupWrap
      className={`${className} ${invisible ? 'invisible-input' : ''}`}
      label={label}
      error={error}
    >
      {invisible && error ? <div className="input-error-icon"></div> : ''}
      <NumericFormat
        data-cy={dataCy || 'number_input'}
        className={`form-control form-field ${innerClassName}`}
        value={value}
        name={name}
        onChange={onChange}
        onValueChange={
          values => autoSaveValue(values.floatValue === undefined ? null : values.floatValue)
          // This handler provides access to any values changes in the input field and is triggered only when a prop changes or the user input changes.
          // The floatValue is already a Number, so there is no need to use the strToNumber to parse it.
        }
        onBlur={onBlur && onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={disabled}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        allowLeadingZeros={false}
        decimalScale={
          decimalScale !== undefined ? decimalScale : allowUnlimitedDecimalScale ? undefined : 2
        }
        {...rest}
      />
    </FormGroupWrap>
  );
};

export default NumberInput;
