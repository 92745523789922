import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CircledButton from 'common/components/buttons/CircledButton';

const ArrowNext = ({ onClick, className }) => {
  const isOnBoard = useSelector(state => state.isOnBoard);

  return (
    <CircledButton
      type="arrow"
      className={className}
      svgWrapperClassName={`${!isOnBoard ? 'bg-dark' : 'bg-primary'}`}
      onClick={onClick}
      svgStyle={{ width: 15, height: 15 }}
    />
  );
};

ArrowNext.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string
};

export default ArrowNext;
