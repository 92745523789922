import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import variables from 'common/assets/scss/abstracts/_exports.module.scss';
import { dateToLocal } from 'common/utils/dates';
import comments from 'common/assets/svg/common/comments.svg';

import { logColorClassName, logText } from './helpers';
import Departments from 'common/components/general/Departments';
import NamesInTooltip from 'common/components/general/NamesInTooltip';
import SvgRender from 'common/components/general/SvgRender';
import { Histories } from 'common/entities/review-process/ReviewProcessTypes';
import { DepartmentRole, Department } from 'common/entities/parties/PartyTypes';
import Textarea from 'common/components/form/inputs/Textarea';

const Text = ({ action, stepNumber, substepLeter, performedBy, performedAt }) => {
  return (
    <div className="text-primary">
      <strong>
        Step {stepNumber}
        {substepLeter ? `.${substepLeter}` : null}
      </strong>{' '}
      was <strong className={logColorClassName(action)}>{logText(action)}</strong> by{' '}
      <strong>{performedBy?.full_name || 'Anonymous User'}</strong> at{' '}
      <strong>{dateToLocal(performedAt).format('DD/MM/YYYY, HH:mm')}</strong>
    </div>
  );
};

Text.propTypes = {
  action: PropTypes.oneOf(['completed, rejected']),
  stepNumber: PropTypes.number.isRequired,
  substepLeter: PropTypes.string,
  performedBy: PropTypes.shape({
    full_name: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    type: PropTypes.string,
    department_roles: PropTypes.arrayOf(DepartmentRole),
    departments: PropTypes.arrayOf(Department)
  }).isRequired,
  performedAt: PropTypes.string.isRequired
};

const DepartmentsWithRoles = ({ departmentRoles, departments }) => {
  return departmentRoles?.length || departments?.length ? (
    <div className="d-flex align-items-center w-100p mt-1 text-violet">
      <Departments values={departments} />
      {departmentRoles?.length ? (
        <>
          <span className="cms-4 cme-6">|</span>
          <NamesInTooltip names={departmentRoles.map(role => role.name)} />
        </>
      ) : null}
    </div>
  ) : null;
};

DepartmentsWithRoles.propTypes = {
  departmentRoles: PropTypes.arrayOf(DepartmentRole),
  departments: PropTypes.arrayOf(Department)
};

const Remarks = ({ remarks }) => {
  if (!remarks) return null;

  return (
    <>
      <div className="d-flex align-items-center mt-2">
        <SvgRender className="text-violet" src={comments} style={{ width: 12, height: 12 }} />
        <span className="text-violet fs-10 cms-6">Remarks</span>
      </div>

      <Textarea
        value={remarks}
        readOnly={true}
        invisible
        rows={3}
        className="cmt-6 fs-12 text-primary"
      />
    </>
  );
};

Remarks.propTypes = {
  remarks: PropTypes.string
};

const Log = ({ log, isLast, isFirst }) => {
  const {
    action,
    step_number,
    substep_letter,
    performed_by,
    performed_at,
    user_department_roles,
    user_departments
  } = log;
  const isCompleted = action === 'completed';
  const isRevoked = action === 'revoked';

  return (
    <Wrapper
      className={`ms-3 py-1 ps-1 fs-12 ${isFirst ? 'mt-0' : 'cmt-12'} ${isLast ? 'last' : ''} ${
        isCompleted ? 'completed' : ''
      } ${isRevoked ? 'revoked' : ''} `}
    >
      <Text
        action={action}
        stepNumber={step_number}
        substepLeter={substep_letter}
        performedBy={performed_by}
        performedAt={performed_at}
      />
      <DepartmentsWithRoles
        departments={user_departments}
        departmentRoles={user_department_roles}
      />
      <Remarks remarks={log.remarks} />
    </Wrapper>
  );
};

export default Log;

Log.propTypes = {
  log: Histories,
  isLast: PropTypes.bool
};

const Wrapper = styled.div`
  background-color: ${variables.bodyBg};
  border-radius: 7px;
  position: relative;

  ::before {
    content: '';
    position: absolute;
    top: ${variables.size10};
    height: 0.8125rem;
    width: 0.8125rem;
    background-color: #fff;
    border-radius: 50%;
    left: -${variables.size24};
    border: 1px solid ${variables.red};
  }

  &:not(.last)::after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: ${variables.primary};
    width: 1px;
    left: -${variables.size18};
    top: ${variables.size22};
  }

  &.completed::after {
    background-color: ${variables.turquoise};
  }

  &.completed::before {
    border: 1px solid ${variables.turquoise};
  }

  &.revoked::after {
    background-color: ${variables.primary};
  }

  &.revoked::before {
    border: 1px solid ${variables.primary};
  }

  .names-in-tooltip__indicator {
    min-width: 1rem;
    max-width: 1rem;
    padding-left: ${variables.size2} !important;
  }
`;
