export const SYSTEM_TASK_TYPE = {
  BULK_UPDATE_FORM_SUBMISSION_STATUSES: 'bulk.update_form_submission_statuses',
  BULK_APPROVE_REVIEW_PROCESS_STEPS: 'bulk.approve_review_process_steps'
} as const;

export type SystemTaskType = (typeof SYSTEM_TASK_TYPE)[keyof typeof SYSTEM_TASK_TYPE];

export type TriggeredSystemTask = {
  id: number;
  execution_type: 'async' | 'sync';
};

type SystemTaskStatus =
  | 'pending'
  | 'processing'
  | 'completed'
  | 'failed'
  | 'terminating'
  | 'terminated';

export type SystemTask = {
  id: number;
  type: SystemTaskType;
  status?: SystemTaskStatus;
  progress?: AsyncSystemTaskProgress;
  event?: AsyncSystemTaskEvent;
  error?: AsyncSystemTaskError;
};

export type AsyncSystemTaskEvent =
  | 'async_action.started'
  | 'async_action.progress'
  | 'async_action.heartbeat'
  | 'async_action.terminated'
  | 'async_action.completed'
  | 'async_action.failed';

export type AsyncSystemTaskProgress = { processed: number; total: number };

type AsyncSystemTaskError = string;

export type AsyncSystemTask = {
  event?: AsyncSystemTaskEvent;
  data: { action_id: number; progress?: AsyncSystemTaskProgress; error?: AsyncSystemTaskError };
};

export type AsyncSystemTaskResultFailureReason = { id: number; message: string };

export type AsyncSystemTaskResult = {
  failed: AsyncSystemTaskResultFailureReason[];
  failed_count: number;
  skipped: AsyncSystemTaskResultFailureReason[];
  skipped_count: number;
  succeed: number[];
  succeed_count: number;
};
