import { selectJobStatuses } from 'store/lists/selectors';
import { useSelector } from 'react-redux';

import SvgRender from 'common/components/general/SvgRender';
import Tooltip from 'common/components/general/Tooltip';

import vesselIcon from 'assets/svg/sidebar/vessels.svg';
import useTooltipID from 'common/utils/hooks/useTooltipID';

export const isJobLocked = (job, statuses) => {
  const status = statuses.find(el => el.id === job.status_id);
  if (!status) return false;

  const { edit_side } = status;

  return edit_side === 'office' ? false : true;
};

const JobIsForVessel = ({ job, vesselName }) => {
  const statuses = useSelector(selectJobStatuses);
  const { updated_by, status, is_for_vessel, id } = job;

  const { avoidRender, tooltipID } = useTooltipID(`job-detaild-view-${id}`);

  if (avoidRender) return null;

  return is_for_vessel ? (
    <div>
      <SvgRender
        id={tooltipID}
        src={vesselIcon}
        style={{ width: 14, height: 14 }}
        className={`me-1 ms-auto cursor-pointer ${
          isJobLocked(job, statuses) ? 'text-violet' : 'text-turquoise'
        }`}
      />
      {isJobLocked(job, statuses) ? (
        <Tooltip target={tooltipID}>{vesselName || 'Vessel'} is processing</Tooltip>
      ) : status?.name ? (
        <Tooltip target={tooltipID} innerClassName="max-width-none">
          Status was changed to <span className="fw-bold">{status?.name}</span>{' '}
          {updated_by?.full_name ? (
            <>
              by <span className="fw-bold">{updated_by?.full_name}</span>
            </>
          ) : null}
        </Tooltip>
      ) : null}
    </div>
  ) : null;
};

export default JobIsForVessel;
