import TYPES from './types';
import PURCHASING_REQUISITION_TYPES from 'common/components/purchasing/requisition/store/types';
import _uniq from 'lodash/uniq';

import {
  getRootItemsIDs,
  getSubItems,
  normalizeTreeStructure,
  toggleNestedItems
} from 'common/components/tree-layout/utils/helpers';

import { LOCATION_CHANGE } from 'connected-react-router';

import paths from 'routing/routes/_paths';
import _orderBy from 'lodash/orderBy';

export const INITIAL_STATE = {
  initialized: false,
  groupsLoading: false,
  singleGroupIsLoading: false,
  groups: {},
  rootGroups: [],
  subGroups: {},
  expandedGroups: {},
  items: [],
  itemsLoading: false,
  itemsPaging: {
    per_page: 100,
    current_page: 1,
    total: 1
  },
  triggerItemRefetch: false,
  activeGroupId: null,
  activeItemId: null,
  activeGroupInModal: { id: null, parentId: null },
  activeItemInModal: null,
  openedModals: { groupForm: false, deleteGroup: false, deleteItem: false },
  treeSearch: { groups: '', items: '', categories: '' },
  isCreatingItem: false,
  purchasingLibraryIsUsedIn: '',
  selectedItemIds: [],
  selectedSettingsCategoryId: null //this is the selected category in Purchasing Categories
};

const reducer = (state = { ...INITIAL_STATE }, { type, payload }) => {
  switch (type) {
    case TYPES.RESET_LIBRARY_SETUP_STATE:
      return INITIAL_STATE;

    case TYPES.GET_PURCHASING_GROUPS.START:
      return { ...state, groupsLoading: true };

    case TYPES.GET_PURCHASING_GROUPS.SUCCESS: {
      const subGroups = getSubItems(payload.data);

      return {
        ...state,
        initialized: !state.initialized ? true : state.initialized,
        groupsLoading: false,
        rootGroups: getRootItemsIDs(payload.data),
        subGroups,
        groups: normalizeTreeStructure(payload.data),
        expandedGroups: payload.params?.search
          ? toggleNestedItems(subGroups, true)
          : state.expandedGroups
      };
    }
    case TYPES.INITIALIZE_SELECTED_LIBRARY_ITEM_IDS:
      return {
        ...state,
        selectedItemIds: payload
      };

    case TYPES.ADD_SELECTED_LIBRARY_ITEM_ID:
      return {
        ...state,
        selectedItemIds: _uniq([...state.selectedItemIds, payload])
      };

    case TYPES.REMOVE_SELECTED_LIBRARY_ITEM_ID:
      return {
        ...state,
        selectedItemIds: state.selectedItemIds.filter(id => id !== payload)
      };

    case TYPES.GET_PURCHASING_GROUPS.ERROR:
      return { ...state, groupsLoading: false, rootGroups: [], subGroups: {}, expandedGroups: {} };

    case TYPES.EXPAND_PURCHASING_GROUP:
      return {
        ...state,
        expandedGroups: { ...state.expandedGroups, [payload.id]: payload.isOpen }
      };

    case TYPES.TOGGLE_PURCHASING_MODAL:
      return {
        ...state,
        openedModals: { ...state.openedModals, [payload]: !state.openedModals[payload] }
      };

    case TYPES.SET_ACTIVE_PURCHASING_GROUP_IN_MODAL:
      return {
        ...state,
        activeGroupInModal: payload
      };

    case TYPES.SET_ACTIVE_PURCHASING_ITEM_IN_MODAL:
      return {
        ...state,
        activeItemInModal: payload
      };

    case TYPES.EDIT_PURCHASING_GROUP.SUCCESS:
      return {
        ...state,
        groups: {
          ...state.groups,
          [payload.id]: { ...state.groups[payload.id], ...payload }
        }
      };

    case TYPES.GET_PURCHASING_GROUP.START:
      return {
        ...state,
        singleGroupIsLoading: true
      };

    case TYPES.GET_PURCHASING_GROUP.SUCCESS:
    case TYPES.GET_PURCHASING_GROUP.ERROR:
      if (payload?.data?.id)
        return {
          ...state,
          singleGroupIsLoading: false,
          groups: {
            ...state.groups,
            [payload.data.id]: { ...state.groups[payload.data.id], ...payload.data }
          }
        };
      else
        return {
          ...state,
          singleGroupIsLoading: false
        };

    case TYPES.SET_ACTIVE_GROUP_ID:
      return { ...state, activeGroupId: payload };

    case TYPES.GET_PURCHASING_ITEMS.START:
      return {
        ...state,
        items: payload.page === 1 ? [] : state.items,
        itemsLoading: true,
        itemsPaging: { ...state.itemsPaging, ...payload.params.paging }
      };

    case TYPES.GET_PURCHASING_ITEMS.SUCCESS:
      return {
        ...state,
        items: payload.current_page === 1 ? payload.items : [...state.items, ...payload.items],
        itemsLoading: false,
        itemsPaging: {
          ...state.itemsPaging,
          current_page: payload.current_page,
          total: payload.total
        }
      };

    case TYPES.SET_TRIGGER_ITEM_REFETCH:
      return {
        ...state,
        triggerItemRefetch: payload
      };

    case TYPES.GET_PURCHASING_ITEMS.ERROR:
      return { ...state, items: [], itemsLoading: false };

    case TYPES.EDIT_PURCHASING_ITEM.SUCCESS:
      return {
        ...state,
        items: state.items.map(item => (item.id === payload.id ? { ...item, ...payload } : item))
      };

    case TYPES.SET_IS_CREATING_ITEM:
      return { ...state, isCreatingItem: payload, activeItemId: null };

    case TYPES.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItemId: payload,
        isCreatingItem: payload ? false : state.isCreatingItem
      };

    case TYPES.SET_TREE_SEARCH:
      return {
        ...state,
        treeSearch: { ...state.treeSearch, [payload.type]: payload.search }
      };

    case TYPES.SET_PURCHASING_LIBRARY_IS_USED_IN:
      return {
        ...state,
        purchasingLibraryIsUsedIn: payload
      };

    case TYPES.SET_SELECTED_SETTINGS_CATEGORY_ID:
      return {
        ...state,
        selectedSettingsCategoryId: payload
      };

    case PURCHASING_REQUISITION_TYPES.ADD_ITEM_TO_PURCHASING_REQUISITION.START:
      return {
        ...state,
        items: payload.hideSelectedItems
          ? state.items.filter(item => item.id !== payload.item.id)
          : state.items
      };

    case PURCHASING_REQUISITION_TYPES.ADD_ITEM_TO_PURCHASING_REQUISITION.ERROR:
      return {
        ...state,
        items: _orderBy([...state.items, payload.item], 'code')
      };

    case LOCATION_CHANGE:
      if (!payload.location.pathname.startsWith(`${paths.purchasing_library_setup}`)) {
        return {
          ...state,
          initialized: false,
          activeItemId: null,
          isCreatingItem: false,
          selectedItemIds: [],
          expandedGroups: {},
          treeSearch: { groups: '', items: '', categories: '' }
        };
      }

      return state;

    default:
      return state;
  }
};

export default reducer;
