// The name of the import should be the same with table label
// Keep the name of the path same with the table label or you will need to parse it here components/layouts/page/table/save-table/helpers.ts

// Captain Reports
import captain_reports from './captain-reports';

// Forms
import form_obligations from './form-obligations';
import form_submissions from './form-submissions';

// Crew
import crew from './crew';
import crew_evaluation_obligations from './evaluation-obligations';

// Events
import events from './events';

// Jobs
import jobs_list from './jobs-list';
import jobs_flag_extensions_dispensations_list from './jobs-flag-extensions-dispensations-list';
import reports_findings from './reports-findings';

// PMS
import captain_reports_running_hours from './running-hours';
import inventory_spare_parts from './inventory-spare-parts';
import pms_jobs_list from './pms_jobs';

// Purchasing
import purchasing_requisitions from './purchasing-requisitions';
import purchasing_deliverables from './deliverables';

const config = {
  captain_reports,
  form_obligations,
  form_submissions,
  crew_evaluation_obligations,
  crew,
  events,
  jobs_list,
  jobs_flag_extensions_dispensations_list,
  reports_findings,
  captain_reports_running_hours,
  inventory_spare_parts,
  purchasing_requisitions,
  purchasing_deliverables,
  pms_jobs_list
};

export default config;
