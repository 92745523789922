import ShadowBox from 'common/components/general/ShadowBox';
import ColoredLabel from 'common/components/labels/ColoredLabel';
import SvgRender from 'common/components/general/SvgRender';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import { useEffect, useMemo, useState } from 'react';
import { Collapse } from 'reactstrap';

import check from 'common/assets/svg/actions/check.svg';
import Checkbox from '@/ts-common/components/form/inputs/Checkbox';

const StepWrapper = ({
  index,
  id, // When null, the step is not created yet
  children,
  substeps,
  steps,
  collapsable = false,
  isDisabled,
  isRequired,
  setIsRequired,
  isMultipleDynamic,
  isConfiguringStep,
  setIsConfiguringStep,
  dynamicStepCanBeConfigured,
  dynamicStepIsAlreadyConfigured,
  onSaveStep,
  onRemoveStep
}) => {
  const [isOpen, setIsOpen] = useState(collapsable ? false : true);

  const approvedSubstepsCount = useMemo(
    () => substeps?.filter(s => s.completed_by).length,
    [substeps]
  );
  const totalSubstepsCount = useMemo(() => substeps?.length, [substeps]);

  const isStepApproved = useMemo(
    () => collapsable && totalSubstepsCount === approvedSubstepsCount,
    [approvedSubstepsCount, collapsable, totalSubstepsCount]
  );

  const hasSomeApprovedSubsteps = useMemo(() => approvedSubstepsCount > 0, [approvedSubstepsCount]);

  useEffect(() => {
    if (collapsable && steps?.findIndex(st => !st?.substeps.every(s => s.completed_by)) === index) {
      // Find and open the first uncompleted step
      setIsOpen(true);
    }
  }, []);

  return (
    <ShadowBox
      className={`${collapsable ? 'cpy-4 cpx-12 cmb-4' : 'cp-12 cmb-4'}`}
      color="light-2"
      flat
    >
      <div className={`review-process-step__header d-flex align-items-center`}>
        <div
          className={`d-flex align-items-center ${
            collapsable && !isConfiguringStep ? 'pointer flex-1' : ''
          }`}
          onClick={collapsable && !isConfiguringStep ? () => setIsOpen(!isOpen) : null}
        >
          <ColoredLabel
            text={`STEP ${index + 1}`}
            index={index}
            className="fw-bold text-uppercase me-3"
          />

          {!!isStepApproved && (
            <ColoredLabel
              text={
                <>
                  <SvgRender src={check} style={{ width: 12, height: 12 }} className="me-1" />
                  Approved
                </>
              }
              className="fs-12 height-20 py-0 fw-medium rounded-xxl ms-auto"
              color="green"
            />
          )}

          {!isStepApproved &&
            !isConfiguringStep &&
            (!dynamicStepCanBeConfigured ||
              (dynamicStepCanBeConfigured && dynamicStepIsAlreadyConfigured)) && (
              <ColoredLabel
                text={
                  <>
                    Pending&nbsp;|&nbsp;
                    <strong>
                      {approvedSubstepsCount}&nbsp;/&nbsp;{totalSubstepsCount}
                    </strong>
                  </>
                }
                className="fs-12 height-20 py-0 fw-medium rounded-xxl ms-auto"
                color="inactive"
                inverted
              />
            )}
        </div>

        {isMultipleDynamic && isConfiguringStep && (
          <Checkbox
            label="Mandatory"
            className="mb-0"
            labelClassName="fs-12 fw-medium text-primary"
            value={isRequired}
            onChange={setIsRequired}
          />
        )}

        {!isStepApproved && dynamicStepCanBeConfigured ? (
          <div className="d-flex align-items-center justify-content-end ms-auto">
            {!isConfiguringStep ? (
              <CircledButton
                className="ms-1"
                size={16}
                svgStyle={{ width: 10, height: 10 }}
                type="edit"
                onClick={() => {
                  setIsConfiguringStep(true);
                  setIsOpen(true);
                }}
                disabled={isDisabled || hasSomeApprovedSubsteps}
              />
            ) : (
              <>
                <CircledButton
                  size={16}
                  svgStyle={{ width: 8, height: 8 }}
                  type="close"
                  onClick={() => setIsConfiguringStep(false)}
                  className="me-1"
                  disabled={isDisabled}
                />
                {!!id && isMultipleDynamic && (
                  <CircledButton
                    size={16}
                    svgStyle={{ width: 8, height: 8 }}
                    type="remove"
                    onClick={onRemoveStep}
                    className="me-1"
                    disabled={isDisabled}
                  />
                )}
                <CircledButton
                  size={16}
                  svgStyle={{ width: 8, height: 8 }}
                  type="save"
                  onClick={onSaveStep}
                  disabled={isDisabled}
                />
              </>
            )}
          </div>
        ) : null}
      </div>
      <Collapse isOpen={isOpen}>
        <div className="cpt-12">{children}</div>
      </Collapse>
    </ShadowBox>
  );
};

export default StepWrapper;
