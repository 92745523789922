import { useState, useMemo } from 'react';
import { download } from 'utils/api';
import { handleFileDownload } from 'common/utils/downloads';

import { useActions, useFormState } from 'utils/hooks';
import { useSelector } from 'react-redux';
import variables from 'common/assets/scss/abstracts/_exports.module.scss';

import * as requisitionActions from 'common/components/purchasing/requisition/store/actions';

import {
  selectActiveRequisitionID,
  selectActiveRequisitionCode
} from 'common/components/purchasing/requisition/store/selectors';
import { Row, Col } from 'reactstrap';
import CkEditor from 'common/components/form/inputs/ckeditor';

import CollapsedFields from './EmailFormCollapsedFields';
import SvgRender from 'common/components/general/SvgRender';
import arrowFilters from 'common/assets/svg/common/arrows/arrow-filters.svg';
import email from 'common/assets/svg/socials/email.svg';
import ExportExcel from 'common/components/general/ExportExcel';
import UploadFiles from '@/ts-common/components/form/inputs/upload-files';
import EmailAttachmentsSync from './EmailAttachmentsSync';
import EmailSupplierExports from './EmailSupplierExports';
import Information from '@/ts-common/components/general/Information';
import FileRemoveButton from '@/ts-common/components/form/inputs/upload-files/FileRemoveButton';
import styled from '@emotion/styled';
import { shouldIncludeDefaultEmailAttachments } from '@/common/components/purchasing/requisition/store/actions.ts';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  selectShouldIncludeDefaultEmailAttachments,
  findTheFirstSupplierWithApprovedQuantity
} from '@/common/components/purchasing/requisition/store/selectors-ts.ts';
import { minimalToolbarConfig } from '@/common/components/form/inputs/ckeditor/config';

const EmailForm = ({ formState, externalEmail, emailStatuses }) => {
  const { fields, selectField } = useFormState(formState);
  const dispatch = useAppDispatch();
  const includeDefaultEmailAttachments = useAppSelector(selectShouldIncludeDefaultEmailAttachments);
  const [isFocused, setIsFocused] = useState(false);

  const [isVisible, setIsVisible] = useState(true);
  const [isDownloading, setIsDownloading] = useState(false);

  const activeID = useSelector(selectActiveRequisitionID);
  const requisitionCode = useSelector(selectActiveRequisitionCode);

  const [getRequisitionRequestPrefix] = useActions([
    requisitionActions.getRequisitionRequestPrefix
  ]);

  const onExcelDownload = async () => {
    if (isDownloading) return;

    const rParams = {
      supplier_id: (fields.to.value || []).length === 1 ? fields.to.value[0].id : undefined
    };

    setIsDownloading(true);

    await handleFileDownload(
      {
        url: `${getRequisitionRequestPrefix()}/${activeID}/rfq/export/excel`,
        filename: `${requisitionCode}_rfq`
      },
      download,
      false,
      {
        requestParams: rParams,
        parsed: true
      }
    );
    setIsDownloading(false);
  };

  const onPdfDownload = async id => {
    if (isDownloading) return;

    setIsDownloading(true);

    await handleFileDownload(
      { url: `purchasing-requisitions/suppliers/${id}/export/pdf` },
      download
    );

    setIsDownloading(false);
  };

  const isQtn = useMemo(
    () => emailStatuses?.find(f => fields.status_id?.value === f?.id)?.label === 'qtn',
    [fields.status_id?.value, emailStatuses]
  );
  const isPo = useMemo(
    () => emailStatuses?.find(f => fields.status_id?.value === f?.id)?.label === 'po',
    [fields.status_id?.value, emailStatuses]
  );

  const fieldTo = Array.isArray(fields.to.value)
    ? fields.to.value.filter(supplier => supplier?.supplier_id)
    : [];

  const firstSupplierWithApprovedQuantity = useAppSelector(state =>
    findTheFirstSupplierWithApprovedQuantity(state, fieldTo)
  );

  const onAttachmentRemove = () => {
    dispatch(shouldIncludeDefaultEmailAttachments(false));
  };

  return (
    <div className="email-form">
      <div
        onClick={() => setIsVisible(!isVisible)}
        className="mb-1 pb-1 border-bottom border-primary cursor-pointer d-flex align-items-center"
      >
        <div className="width-24 height-24 bg-primary text-white rounded-circle d-flex justify-content-center align-items-center me-1">
          <SvgRender
            src={email}
            style={{
              width: 12,
              height: 12
            }}
          />
        </div>
        <strong className="text-primary fs-10 lh-12">AUTO EMAIL</strong>
        <div className="width-16 height-16 text-primary border border-primary rounded-circle d-flex justify-content-center align-items-center ms-auto">
          <SvgRender
            src={arrowFilters}
            className="base-transition"
            style={{
              width: 8,
              height: 8,
              transform: !isVisible ? 'rotate(0deg)' : 'rotate(180deg)'
            }}
          />
        </div>
      </div>

      <CollapsedFields
        emailStatuses={emailStatuses}
        fields={fields}
        selectField={selectField}
        isVisible={isVisible}
      />

      <div className="position-relative">
        <EmailContainer
          className={`email-form__group--body overflow-auto cmy-6 ${
            isVisible ? 'collapse-open' : 'collapse-closed'
          }`}
          isVisible={isVisible}
        >
          <CkEditor
            placeholder="Add some text"
            onChange={html => selectField('body')(html)}
            customToolbar={minimalToolbarConfig}
            isFocused={isFocused}
            onFocus={focus =>
              focus ? setIsFocused(true) : setTimeout(() => setIsFocused(false), 100)
            }
            minHeight={isVisible ? variables.size238 : variables.size138}
            value={fields.body?.value ?? '<div></div>'}
          />
        </EmailContainer>

        <div className="email-form__group--body_code text-violet fs-12 fw-medium ">
          [{requisitionCode}]
        </div>
      </div>
      <Row className="border-top pt-1 g-0">
        <Col xs={4} className="d-flex flex-nowrap align-items-center pe-1 position-relative">
          {externalEmail ? (
            <Information
              message={
                <div className="text-start">
                  You can upload:
                  <br />
                  Maximum {externalEmail.max_files_size} of attachments
                  <br />
                  Maximum {externalEmail.max_files_count} attachments.
                </div>
              }
              className="ms-n2 me-1 z-index-1 cursor-pointer"
              tooltipClassname="max-width-none"
              svgClassname="text-primary"
              svgStyle={{ width: 12, height: 12 }}
            />
          ) : null}

          {isQtn && includeDefaultEmailAttachments ? (
            <Container>
              <ExportExcel
                className="me-1"
                showLabel={false}
                onClick={() => onExcelDownload()}
                svgStyle={{ width: 13, height: 13 }}
                disabled={isDownloading}
              />
              <FileRemoveButton size={'sm'} onClick={onAttachmentRemove} />
            </Container>
          ) : null}

          {!isQtn && firstSupplierWithApprovedQuantity?.id && (
            <EmailSupplierExports
              supplier={firstSupplierWithApprovedQuantity}
              emailStatuses={emailStatuses}
              isPo={isPo}
              isDownloading={isDownloading}
              onPdfDownload={onPdfDownload}
            />
          )}

          <UploadFiles
            group="purchasing.supplier-emails"
            files={fields.attachments.value}
            onChange={selectField('attachments')}
            size="sm"
            className="mb-0"
          />
        </Col>

        <Col xs={4} className="cpe-4">
          <EmailAttachmentsSync
            onChange={files => selectField('synced_office_attachments')(files)}
            attachments={fields.synced_office_attachments.value}
            type="office"
          />
        </Col>
        <Col xs={4} className="cps-4">
          <EmailAttachmentsSync
            onChange={files => selectField('synced_vessel_attachments')(files)}
            attachments={fields.synced_vessel_attachments.value}
            type="vessel"
          />
        </Col>
      </Row>
    </div>
  );
};

export default EmailForm;

const Container = styled.div`
  position: relative;
  .file-remove-icon {
    right: ${variables.size2};
  }
  &:hover {
    .file-remove-icon {
      opacity: 1;
    }
  }
`;

const EmailContainer = styled.div(
  ({ isVisible }) => `
min-height: ${isVisible ? variables.size238 : variables.size138};
  max-height: ${isVisible ? variables.size238 : variables.size138};

  .ck-content {
    min-height: ${variables.size138};
    max-height: ${variables.size160};
  }
`
);
