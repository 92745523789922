import { Button } from 'reactstrap';
import { useEffect, useState } from 'react';

import SvgRender from 'common/components/general/SvgRender';

import SelectColumnOperation from 'common/components/filters/SelectColumnOperation';
import SelectValue from 'common/components/filters/SelectValue';
import TableTopFilter from 'common/components/table/TableTopFilter';

import types from 'common/utils/filters/operators';
import close from 'common/assets/svg/actions/select-close.svg';
import useTableTopFilterValue from 'common/components/filters/useTableTopFilterValue';
import useRouter from 'use-react-router';
import _isArray from 'lodash/isArray';
import { parseQueryParams } from 'utils/urls';

const DueDateTopFilter = ({ topFilters, updateTopFilters, disabled = undefined }) => {
  const keepSelectedValueOperations = ['=', '>', '<']; // Reset selected value when any other operator
  const [localStateOperation, setLocalStateOperation] = useState(keepSelectedValueOperations[0]);
  const [isInitialized, setIsInitialized] = useState(false);

  const { history } = useRouter();

  useEffect(() => {
    if (history.location.search && !isInitialized) {
      const { filters } = parseQueryParams(history.location.search);
      const selectedDueDateFilter = filters?.find(f => f.name === 'due_date');

      if (
        selectedDueDateFilter?.operation &&
        selectedDueDateFilter?.operation !== localStateOperation
      ) {
        setLocalStateOperation(selectedDueDateFilter?.operation);
        setIsInitialized(true);
      }
    }
  }, [history.location.search, isInitialized, localStateOperation]);

  const [dueDateFilter] = useTableTopFilterValue({ topFilters }, 'due_date');

  return (
    <TableTopFilter>
      <div
        className={`filter-handler table-standalone-top-filter position-relative me-1 ${
          disabled ? 'disabled pointer-events-none' : ''
        }`}
      >
        <div className="filter-handler--field cps-12 cpe-12 d-inline-flex align-items-center">
          <span className="fw-medium fs-12 text-primary">Due Date</span>
        </div>
        <SelectColumnOperation
          isOperation
          value={types.date.operations.find(o => o.value === localStateOperation)}
          onSelect={operation => {
            updateTopFilters({
              value:
                keepSelectedValueOperations.includes(localStateOperation) &&
                keepSelectedValueOperations.includes(operation.value)
                  ? dueDateFilter
                  : null,
              operation: operation.value
            });
            setLocalStateOperation(operation?.value);
          }}
          options={types.date.operations}
        />
        <SelectValue
          filter={{
            value:
              localStateOperation === 'between'
                ? _isArray(dueDateFilter)
                  ? { from: dueDateFilter?.[0], to: dueDateFilter?.[1] }
                  : null
                : dueDateFilter,
            column: { type: 'date' },
            operation: { value: localStateOperation }
          }}
          updateFilter={({ value }) => {
            if (localStateOperation === 'between' && value.from && value.to) {
              updateTopFilters({
                value: [value.from, value.to],
                operation: localStateOperation
              });
            } else {
              updateTopFilters({ value, operation: localStateOperation });
            }
          }}
        />

        {dueDateFilter ? (
          <Button
            onClick={() => updateTopFilters('due_date', null)}
            type="button"
            className="d-inline-flex align-items-center table-standalone-top-filter__clear-btn p-0"
            color="link"
          >
            <SvgRender src={close} style={{ width: 16, height: 16 }} />
          </Button>
        ) : null}
      </div>
    </TableTopFilter>
  );
};

export default DueDateTopFilter;
