import PersonSelector from 'common/components/selectors/PersonSelector';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import CrewReasonSelector from 'common/components/selectors/CrewReasonsSelector';
import starIcon from '@/common/assets/svg/common/star.svg';
import TopFilters from './TopFilters';

const getColumns = (isInCompletedTab: boolean) => [
  {
    header: 'Reason',
    key: 'reason',
    sort: true,
    canFilter: false,
    minWidth: 316,
    maxWidth: 316,
    sticky: true,
    left: 0,
    className: 'z-index-3'
  },
  {
    header: 'Reason',
    key: 'reason_id',
    sort: false,
    canFilter: true,
    className: 'd-none',
    type: 'collection',
    hidden: true,
    component: CrewReasonSelector,
    componentRest: { isMulti: true, params: { include_custom: true } }
  },
  {
    header: 'Evaluator',
    key: 'evaluator',
    sort: false,
    canFilter: false,
    minWidth: 300,
    maxWidth: 300
  },
  {
    header: 'Evaluatee',
    key: 'evaluatee',
    sort: true,
    canFilter: false,
    minWidth: 278,
    maxWidth: 278
  },
  {
    header: 'Evaluatee',
    key: 'evaluatee_id',
    sort: false,
    canFilter: true,
    hidden: true,
    className: 'd-none',
    type: 'collection',
    component: PersonSelector,
    componentRest: { isMulti: true, listParams: { onboard: true } }
  },
  {
    header: 'Rank',
    key: 'rank',
    sort: true,
    canFilter: false,
    minWidth: 269,
    maxWidth: 269
  },
  {
    header: 'Rank',
    key: 'rank_id',
    type: 'collection',
    component: CrewRankSelector,
    componentRest: { isMulti: true },
    sort: false,
    canFilter: true,
    hidden: true,
    className: 'd-none'
  },
  {
    header: 'Contract Starts',
    key: 'contract_starts',
    data_key: 'trigger.contract_from_date',
    sortingKey: 'trigger_contract_from',
    type: 'date',
    sort: true,
    canFilter: false,
    minWidth: 136,
    maxWidth: 136
  },
  {
    header: 'Contract Ends',
    key: 'contract_ends',
    data_key: 'trigger.contract_to_date',
    sortingKey: 'trigger_contract_to',
    type: 'date',
    sort: true,
    canFilter: false,
    minWidth: 132,
    maxWidth: 132
  },
  {
    header: 'DOS',
    key: 'trigger_date',
    type: 'date',
    sort: true,
    canFilter: false,
    minWidth: 124,
    maxWidth: 124
  },
  {
    header: 'Days Left',
    key: 'days_left',
    sort: true,
    type: 'date',
    hidden: isInCompletedTab,
    canFilter: false,
    minWidth: 100,
    maxWidth: 100
  },
  {
    header: 'Evaluation Date',
    key: 'evaluation_date',
    sort: true,
    type: 'date',
    hidden: !isInCompletedTab,
    canFilter: isInCompletedTab,
    minWidth: 134,
    maxWidth: 134
  },
  {
    header: '',
    key: 'evaluation_status',
    sort: false,
    canFilter: false,
    sticky: true,
    right: 0,
    minWidth: isInCompletedTab ? 114 : 160,
    maxWidth: isInCompletedTab ? 114 : 160,
    isRightSticky: true
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions',
    className: 'text-center'
  }
];

export const getTopFilters = () => [
  {
    name: 'status',
    operation: '=',
    value: 'pending'
  }
];

const config = {
  getColumns,
  getTopFilters,
  isNewTable: false,
  topFiltersComponent: TopFilters,
  icon: starIcon,
  tableStyle: 'default',
  hideTableReorder: false,
  isModular: false,
  hideTableFilters: false,
  name: 'Evaluation Obligations',
  pageTitleComponent: (
    <div className="d-flex align-items-center">
      Evaluation Obligations <div className="fw-bold ms-1">/ Pending</div>
    </div>
  ),
  entity: 'evaluation-obligations'
};

export default config;
