import OvalButton from 'common/components/buttons/OvalButton';
import { FC } from 'react';

type InputSaveActionsProps = {
  onCancel?: () => void;
  onSave?: () => void;
  canSubmit?: boolean;
  className?: string;
};

const InputSaveActions: FC<InputSaveActionsProps> = ({
  onCancel,
  onSave,
  className,
  canSubmit
}) => {
  return (
    <>
      {onSave && onCancel ? (
        <div
          className={`input-save-actions d-flex align-items-center justify-content-end ${className} `}
        >
          <OvalButton
            type="close"
            onClick={onCancel}
            size="sm"
            className={`me-1`}
            disabled={false}
          />
          <OvalButton type="send" disabled={!canSubmit} size="sm" onClick={onSave} text="Submit" />
        </div>
      ) : null}
    </>
  );
};

export default InputSaveActions;
