import React from 'react';
import { components } from 'react-select';
import crewPlanning from 'common/assets/svg/common/crew_planning.svg';
import SvgRender from 'common/components/general/SvgRender';
import crew from 'common/assets/svg/crew/rank.svg';
import CreateOption from 'common/components/general/CreateOption';

import { getDepartmentColor, getDepartmentName } from 'common/components/selectors/_helpers';
import { getPartyDisplayName } from 'common/utils/labels';

export const PartyIcon = ({ type, data }) => {
  return type === 'crew_rank' ? (
    <SvgRender
      className="text-primary "
      style={{ width: 14, height: 14, marginRight: 6 }}
      src={crew}
    />
  ) : type === 'crew' ? (
    <SvgRender
      className="text-violet "
      style={{ width: 14, height: 14, marginRight: 6 }}
      src={crewPlanning}
    />
  ) : (
    getDepartmentColor(data)
  );
};

export const Option = ({ children, ...props }) => {
  const type = props.data.type || props.data.entity_type;

  return (
    <components.Option {...props} className="w-100p">
      {props?.data?.__isNew__ ? (
        <CreateOption label="Create new" />
      ) : props.data ? (
        <div className="d-flex align-items-center justify-content-between fs-12">
          <span className="text-truncate">{getPartyDisplayName(props.data)}</span>
          <div className="d-flex align-items-center justify-content-center">
            <PartyIcon type={type} data={props.data} />
          </div>
        </div>
      ) : null}
    </components.Option>
  );
};

export const OptionFullDepartment = ({ children, ...props }) => {
  return (
    <components.Option {...props} className="w-100p">
      {props.data ? (
        <div className="d-flex align-items-center justify-content-between fs-12">
          <span className="text-truncate">{getPartyDisplayName(props.data)}</span>
          {getDepartmentName(props.data)}
        </div>
      ) : null}
    </components.Option>
  );
};

export const SingleValue = ({ children, ...props }) => {
  const type = props.data.type || props.data.entity_type;

  return (
    <components.SingleValue {...props}>
      {props.data ? (
        <div className="party-select-single-value d-flex align-items-center fs-12">
          <div className="d-flex align-items-center justify-content-center cme-12">
            <PartyIcon type={type} data={props.data} />
          </div>
          <span className="text-truncate">{getPartyDisplayName(props.data)}</span>
        </div>
      ) : null}
    </components.SingleValue>
  );
};

export const SingleValueFullDepartment = ({ children, ...props }) => {
  return (
    <components.SingleValue {...props}>
      {props.data ? (
        <div className="party-select-single-value d-flex align-items-center justify-content-between">
          <div className="text-truncate cme-12 d-block">{getPartyDisplayName(props.data)}</div>

          <div className="w-auto">{getDepartmentName(props.data)}</div>
        </div>
      ) : null}
    </components.SingleValue>
  );
};

export const MultiValue = ({ children, ...props }) => {
  const type = props.data.type || props.data.entity_type;

  return (
    <components.MultiValue {...props}>
      {props.data ? (
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center justify-content-center cme-6 cps-4">
            <PartyIcon type={type} data={props.data} />
          </div>
          <span className="text-truncate">{getPartyDisplayName(props.data)}</span>
        </div>
      ) : null}
    </components.MultiValue>
  );
};
