import { FC } from 'react';
import { Department, DepartmentRole } from '@/common/types/enums';
import Departments from 'common/components/general/Departments';
import TextWithTooltip from '@/ts-common/components/general/TextWithTooltip';

type DepartmentsActionsProps = {
  departments?: Department[];
  departmentRoles?: DepartmentRole[];
  index: number;
};

const DepartmentsActions: FC<DepartmentsActionsProps> = ({
  departments,
  departmentRoles,
  index
}) => {
  return (
    <>
      {index === 0 && (
        <div className="text-violet fs-12 fw-medium mb-1">
          Department(s) that have to take action - Either one of the selected departments can fill
          in info.
        </div>
      )}

      <div className="cmb-20 d-flex align-items-center flex-nowrap">
        {departments?.length ? <Departments values={departments} maxLimit={2} /> : null}
        {departmentRoles?.length ? (
          <div
            className={`flex-1 overflow-hidden text-primary fs-12 fw-medium ${
              departments?.length ? 'cps-12 cms-12 border-start' : ''
            }`}
          >
            <TextWithTooltip>{departmentRoles.map(d => d.name).join(', ')}</TextWithTooltip>
          </div>
        ) : null}

        {!departments?.length && !departmentRoles?.length ? '-' : ''}
      </div>
    </>
  );
};

export default DepartmentsActions;
