import { useEffect, useRef, useState } from 'react';

const SavingState = Object.freeze({
  NOT_SAVED: 0,
  SAVING: 1,
  SAVED: 2
} as const);

export type SavingStateType = (typeof SavingState)[keyof typeof SavingState];

const useAutoSaveInputValue = <T = string>(autoSaveFunc: (updatedValue: T) => Promise<unknown>) => {
  const [saving, setSaving] = useState<SavingStateType>(SavingState.NOT_SAVED);
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const onAutoSave = (updatedValue: T) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setSaving(SavingState.NOT_SAVED);

    timerRef.current = setTimeout(() => {
      setSaving(SavingState.SAVING);

      autoSaveFunc(updatedValue).then(() => {
        setSaving(SavingState.SAVED);
      });
    }, 600);
  };

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return { savingState: saving, onAutoSave };
};

export default useAutoSaveInputValue;
