import { useState, useEffect, useCallback } from 'react';
import Form from 'common/components/review-process/components/notes/form/index';
import Preview from 'common/components/review-process/components/notes/preview/index';
import { useForm, useFormState } from 'utils/hooks';
import { config } from 'common/components/review-process/components/notes/config';
import Loader from 'common/components/tree-layout/components/Loader';
import CircledButton from '@/ts-common/components/buttons/CircledButton';
import ShadowBox from 'common/components/general/ShadowBox';
import {
  selectReviewProcessNotes,
  selectReviewProcessId
} from 'common/components/review-process/store/selectors';
import {
  selectReviewProcessCreatedBy,
  selectReviewProcessCreatedAt
} from '@/common/components/review-process/store/selectors-ts.ts';
import { getReviewProcessNotes } from 'common/components/review-process/store/actions';
import PropTypes from 'prop-types';
import { useAppSelector, useAppDispatch } from '@/store/hooks';
import { displayDate } from '@/ts-common/utils/dates';

const Notes = ({ activeEntityId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [active, setActive] = useState(false);
  const [activeNote, setActiveNote] = useState(null);

  const reviewProcessId = useAppSelector(state => selectReviewProcessId(state, activeEntityId));
  const reviewProcessNotes = useAppSelector(selectReviewProcessNotes);
  const reviewProcessCreatedBy = useAppSelector(state =>
    selectReviewProcessCreatedBy(state, activeEntityId)
  );
  const reviewProcessCreatedAt = useAppSelector(state =>
    selectReviewProcessCreatedAt(state, activeEntityId)
  );

  const { formState, loadValues } = useForm(config, {});
  const { fields, selectField } = useFormState(formState);

  const dispatch = useAppDispatch();

  const onInit = useCallback(async () => {
    if (reviewProcessId) {
      try {
        setIsLoading(true);

        await dispatch(getReviewProcessNotes({ id: reviewProcessId })).unwrap();

        setIsLoading(false);
      } catch (e) {
        console.error(e);
      }
    }
  }, [reviewProcessId, dispatch]);

  const onAdd = () => {
    setActiveNote(null);
    setShowForm(true);
  };

  const onCancelForm = () => {
    setActiveNote(null);
    setShowForm(false);
    setActive(null);
  };

  useEffect(() => {
    onInit();
  }, [onInit]);

  if (!reviewProcessId) return;

  return (
    <div className="w-100p mb-2 mt-2">
      {!showForm && !activeNote?.id ? (
        <ShadowBox className="py-2 px-2  mb-2 rounded-11" color="light-2" flat>
          <CircledButton
            size={20}
            type="add"
            onClick={onAdd}
            label={reviewProcessNotes?.length ? 'Add Comment' : 'Start a Conversation'}
            labelClassName="fs-14"
          />
        </ShadowBox>
      ) : null}
      <>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {showForm && !active && (reviewProcessId ? !activeNote?.id : !activeNote?.index) ? (
              <>
                <Form
                  fields={fields}
                  selectField={selectField}
                  loadValues={loadValues}
                  setActiveNote={setActiveNote}
                  activeNote={activeNote}
                  reviewProcessId={reviewProcessId}
                  closeForm={onCancelForm}
                />
              </>
            ) : null}
            {reviewProcessCreatedBy && reviewProcessCreatedAt ? (
              <div className="text-violet fs-12">
                Approval Process initiated by:&nbsp;
                <strong>
                  {reviewProcessCreatedBy} - {displayDate(reviewProcessCreatedAt, { time: true })}
                </strong>
              </div>
            ) : null}
            {reviewProcessNotes?.length ? (
              <div className="mt-1">
                {reviewProcessNotes.map((note, index) => (
                  <Preview
                    key={note.id}
                    index={index}
                    note={note}
                    closeForm={onCancelForm}
                    isLoading={isLoading}
                    activeNote={activeNote}
                    setActiveNote={setActiveNote}
                    setShowForm={setShowForm}
                    reviewProcessId={reviewProcessId}
                    fields={fields}
                    selectField={selectField}
                    loadValues={loadValues}
                  />
                ))}
              </div>
            ) : null}
          </>
        )}
      </>
    </div>
  );
};

Notes.propTypes = {
  activeEntityId: PropTypes.number.isRequired
};

export default Notes;
