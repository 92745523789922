import { Row, Col } from 'reactstrap';
import { useAppSelector } from '@/store/hooks';
import { useLocation } from 'react-router-dom';
import { parseQueryParams, stringifyObj } from '@/ts-common/utils/urls';
import { TableProps, TopPagination } from '@webthatmatters/orca-table';
import {
  BecomingDueFilter,
  OverdueFilter,
  PostponedFilter
} from '@/common/components/pms/jobs/components/StatusFilters';

import VesselSelector from 'common/components/selectors/VesselSelector';
import TableTopFilter from 'common/components/table/TableTopFilter';
import DueDateTopFilter from 'common/components/jobs/_base/components/DueDateTopFilter';
import TableExcelExport from 'common/components/table/TableExcelExport';
import TableTopFilterNumberField from 'common/components/table/TableTopFilterNumberField';
import FilterButton from 'common/components/buttons/FilterButton';
import useRouter from 'use-react-router';
import RunningHoursReportTooltip from '../../pms/jobs/components/RunningHoursReportTooltip';
import { Vessel } from '@/common/types/vessel';

type TopFiltersProps = { doneJobsFilterEnabled?: boolean } & TableProps<any>;

const TopFilters = ({ doneJobsFilterEnabled, ...table }: TopFiltersProps) => {
  const { topFilters, label, setTopFilter, requestParams, getTopFilterValue } = table;

  const selectedVessel = topFilters?.find(f => f.name === 'vessel_id');
  const selectedProgress = doneJobsFilterEnabled;
  const selectedOverdue = (getTopFilterValue('timing') as string[] | null)?.includes('is_overdue');
  const selectedBecomingDue = (getTopFilterValue('timing') as string[] | null)?.includes(
    'becoming_due'
  );
  const selectedPostponed = (getTopFilterValue('timing') as string[] | null)?.includes(
    'is_postponed'
  );

  const selectedRunningHours = topFilters?.find(f => f.name === 'remaining_rh_until_due');

  const isOnBoard = useAppSelector(state => state.isOnBoard);

  const { search: stringifiedRequestParams } = useLocation();
  const { history } = useRouter();

  const updateTimingFilters = (key: string) => {
    const timingValue = (getTopFilterValue('timing') as string[] | null) || [];
    const newTimingValue = timingValue.includes(key)
      ? timingValue.filter(t => t !== key)
      : [...timingValue, key];

    setTopFilter('timing', newTimingValue.length ? newTimingValue : null);
  };

  const onClickDoneJobs = () => {
    if (!doneJobsFilterEnabled) {
      setTopFilter('is_completed', 'true');
    } else {
      const urlParams = parseQueryParams(stringifiedRequestParams);

      const updatedUrlParams = urlParams;

      if (urlParams.sorting && 'carried_out_at' in urlParams.sorting) {
        const { carried_out_at, ...rest } = urlParams.sorting;

        updatedUrlParams.sorting = rest;
      }

      updatedUrlParams.filters = urlParams.filters?.filter(f => f.name !== 'is_completed');

      history.replace({
        pathname: history.location.pathname,
        search: stringifyObj(updatedUrlParams)
      });
    }
  };

  return (
    <Row className="d-flex align-items-center cmb-4 w-100p" noGutters>
      {!isOnBoard ? (
        <Col xs="auto" className="me-1">
          <TableTopFilter>
            <VesselSelector
              filter={{
                value: (selectedVessel?.value as Vessel[] | null)?.length
                  ? selectedVessel?.value
                  : null
              }}
              isMulti={true}
              isClearable
              autoFocus={false}
              onChange={({ value }: { value: Vessel[] | null }) =>
                setTopFilter('vessel_id', value?.length ? value : null)
              }
              placeholder="Select vessel"
            />
          </TableTopFilter>
        </Col>
      ) : null}

      <Col xs="auto">
        <TableTopFilterNumberField
          selectedFilter={selectedRunningHours as any}
          onUpdate={({ value, operation }) => {
            if (isNaN(value)) return null;
            setTopFilter('remaining_rh_until_due', value, operation);
          }}
          label="Running Hours"
          info={
            <div>
              <div className="mb-3">
                Here we filter{' '}
                <strong>running hours until job due, based on the last running hours report</strong>{' '}
                for each system. We do NOT filter running hours due based on total system RH.
              </div>
              <strong className="fs-10">Example:</strong>
              <div>Last Running Hours report has Total System RH 7,800.</div>
              <div>Job for the above system, has RH Due 8,000.</div>
              <div>Job will be Due in 200 Running hours and this is what we filter.</div>
            </div>
          }
        />
      </Col>
      <Col xs="auto">
        <DueDateTopFilter
          topFilters={topFilters}
          updateTopFilters={({ value, operation }: { value: unknown; operation: string }) =>
            setTopFilter('due_date', value, operation)
          }
        />
      </Col>

      <Col xs="auto" className="me-1">
        <BecomingDueFilter
          selectedBecomingDue={selectedBecomingDue}
          updateTopFilters={updateTimingFilters}
        />
      </Col>
      <Col xs="auto" className="me-1">
        <OverdueFilter selectedOverdue={selectedOverdue} updateTopFilters={updateTimingFilters} />
      </Col>
      <Col xs="auto" className="me-1">
        <PostponedFilter
          selectedPostponed={selectedPostponed}
          updateTopFilters={updateTimingFilters}
        />
      </Col>

      <Col xs="auto">
        <FilterButton
          isActive={selectedProgress}
          onClick={
            !isOnBoard
              ? onClickDoneJobs
              : () => setTopFilter('is_completed', selectedProgress ? null : 'true')
          }
          className="cme-2"
          size="sm"
        >
          History
        </FilterButton>
      </Col>
      {!isOnBoard && (
        <>
          <Col xs="auto" className="ps-2 ms-2 border-start d-flex align-items-center">
            <TableExcelExport
              className="ms-auto me-1"
              label={label}
              exportUrl="/vessel-systems/maintenance-jobs/export"
              requestParams={requestParams}
            />
          </Col>
          <Col xs="auto" className="ps-2 ms-2 border-start d-flex align-items-center">
            <RunningHoursReportTooltip
              vesselId={
                Array.isArray(selectedVessel?.value) && selectedVessel.value.length === 1
                  ? selectedVessel.value[0]
                  : null
              }
            />
          </Col>
        </>
      )}

      {label && (
        <Col className="ms-auto app-table">
          <div className="app-table--toptotal d-flex align-items-center justify-content-end">
            <TopPagination {...table} />
          </div>
        </Col>
      )}
    </Row>
  );
};

export default TopFilters;
