import { useCallback, useMemo } from 'react';
import CollapseForm from 'common/components/collapse/CollapseForm';
import SvgRender from 'common/components/general/SvgRender';
import TextWithTooltip from 'common/components/general/TextWithTooltip';
import arrowFilter from 'common/assets/svg/common/arrows/arrow-filters.svg';
import comments from 'common/assets/svg/common/comments.svg';
import { PreviewHtml } from 'common/components/form/inputs/Editor';
import { useSelector } from 'react-redux';
import { Row, Col, Button } from 'reactstrap';
import {
  selectRequisitionSuppliers,
  selectActiveRequisitionSupplierIds,
  selectRequisitionItems
} from 'store/purchasing/requisition-summary/selectors';
import {
  selectTotalApprovedPriceBaseCurrencyEquivalent,
  selectTotalProposedPriceBaseCurrencyEquivalent,
  selectTotalDiscountedPriceBaseCurrencyEquivalent
} from '@/store/purchasing/requisition-summary/selectors-ts.ts';
import {
  selectSupplierDeliveryRemarks,
  selectSupplierRemarks,
  selectSupplierIsRejected,
  selectSupplier
} from 'common/components/purchasing/requisition/store/selectors';
import { numberToStr, strToNumber } from 'common/utils/numbers';
import { dateToLocal } from 'common/utils/dates';
import { useDispatch } from 'react-redux';
import { setSupplierIsActive } from 'store/purchasing/requisition-summary/slice';
import { selectListDefaultOptions } from 'store/lists/selectors';
import { useAppSelector } from '@/store/hooks';
import { displaySupplierDeliveryPlace } from '@/common/components/purchasing/requisition/store/helpers-ts.ts';
import { displayDate } from '@/ts-common/utils/dates';
import calendar from 'common/assets/svg/common/calendar-bordered.svg';
import location from '@/assets/svg/sidebar/the-fleet.svg';

const Supplier = ({ data }) => {
  const dispatch = useDispatch();
  const activeRequisitionSupplierIds = useSelector(selectActiveRequisitionSupplierIds);
  const items = useSelector(selectRequisitionItems);
  const requisitionStatuses = useSelector(state =>
    selectListDefaultOptions(state, 'purchasing-requisition-statuses')
  );

  const deliveryRemarks = useSelector(state => selectSupplierDeliveryRemarks(state, data.id));
  const remarks = useSelector(state => selectSupplierRemarks(state, data.id));
  const totalApprovedPrice = useAppSelector(state =>
    selectTotalApprovedPriceBaseCurrencyEquivalent(state, data.id)
  );
  const totalProposedPrice = useAppSelector(state =>
    selectTotalProposedPriceBaseCurrencyEquivalent(state, data.id)
  );
  const totalDiscountedPrice = useAppSelector(state =>
    selectTotalDiscountedPriceBaseCurrencyEquivalent(state, data.id)
  );

  const supplierIsRejected = useSelector(state => selectSupplierIsRejected(state, data?.id));
  const supplier = useSelector(state => selectSupplier(state, data.id));

  const poStatusIndex = useMemo(
    () => requisitionStatuses.find(status => status.label === 'po')?.sort_index,
    [requisitionStatuses]
  );
  const purchaseOrderIsProceeded = data?.status?.sort_index >= poStatusIndex;

  const isRfq = data?.status?.label === 'rfq';
  const isActive = useMemo(
    () => activeRequisitionSupplierIds.includes(data.id),
    [activeRequisitionSupplierIds, data.id]
  );

  const onClick = useCallback(() => dispatch(setSupplierIsActive(data.id)), [dispatch, data.id]);

  const supplierHasItemsWithProposedQuantity = items
    .map(item => item.supplier_details.find(s => s.requisition_supplier_id === supplier?.id))
    .some(supplierItem => strToNumber(supplierItem?.proposed_quantity) > 0);

  const deliveryPlace = displaySupplierDeliveryPlace(data, '', false);

  return (
    <div className="requisition-supplier cmt-2">
      <CollapseForm
        className={`requisition-supplier__form requisition-supplier__form--summary ${
          purchaseOrderIsProceeded && supplierHasItemsWithProposedQuantity ? 'proceeded' : ''
        }`}
        headerClassName="w-100p"
        triggerOpen={isActive}
        header={
          <Row className="text-primary fs-12 pointer" onClick={onClick} noGutters>
            <Col className="min-w-29p max-w-29p ps-4 me-1">
              <TextWithTooltip>{data.supplier?.full_name || ''}</TextWithTooltip>
            </Col>

            <Col className="min-w-7p max-w-7p">
              {isRfq ? (
                <span className="fw-medium fs-10 text-violet">No QTN</span>
              ) : supplierIsRejected ? (
                <span className="fw-medium fs-10 text-red">Rejected</span>
              ) : supplier.po_sent_at ? (
                dateToLocal(supplier.po_sent_at).format('DD/MM/YYYY')
              ) : (
                ''
              )}
            </Col>

            <Col className="min-w-12p max-w-12p text-end me-2">
              {numberToStr(totalDiscountedPrice, 2, 2)}
            </Col>

            <Col className="min-w-12p max-w-12p text-end me-2">
              {numberToStr(totalProposedPrice, 2, 2)}
            </Col>

            <Col className="min-w-12p max-w-12p text-end me-6">
              {numberToStr(totalApprovedPrice, 2, 2)}
            </Col>

            <Col className="sm-w-10p mdlaptop-w-14p hd-w-15p lghd-w-18p cpe-2">
              <TextWithTooltip>{deliveryRemarks}</TextWithTooltip>
            </Col>
          </Row>
        }
        customExpandComponent={() =>
          isRfq ? (
            <div />
          ) : (
            <Button
              color="link"
              className="p-0 position-absolute border border-primary rounded-circle text-primary d-inline-flex align-items-center justify-content-center requisition-supplier__form--summary-toggle"
              onClick={onClick}
            >
              <SvgRender
                src={arrowFilter}
                style={{ width: 7, height: 7, transform: isActive ? `` : 'rotate(180deg)' }}
              />
            </Button>
          )
        }
        hideExpandButton={true}
        hideActions={true}
        disableCollapse={true}
      >
        <div className="text-primary fs-12 pt-1 ps-1 flex-1">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center flex-1">
              <SvgRender src={comments} style={{ width: 12, height: 12 }} />
              <span className="cps-4 fs-12 fw-medium lh-1 text-primary">Remarks</span>
            </div>

            <div className="d-flex align-items-center text-primary fw-medium fs-12">
              {deliveryPlace ? (
                <div className="d-flex align-items-center me-7">
                  <SvgRender src={location} style={{ width: 12, height: 12 }} />
                  <div className="cps-4">{deliveryPlace}</div>
                </div>
              ) : null}

              {data.delivery_date ? (
                <div className="d-flex align-items-center">
                  <SvgRender src={calendar} style={{ width: 12, height: 12 }} />
                  <div className="cps-4">{displayDate(data.delivery_date, { time: true })}</div>
                </div>
              ) : null}
            </div>
          </div>
          <div className={`${remarks ? 'cmt-6' : ''}`}>
            <PreviewHtml value={remarks} />
          </div>
        </div>
      </CollapseForm>
    </div>
  );
};

const Suppliers = () => {
  const suppliers = useSelector(selectRequisitionSuppliers);

  return (
    <>
      <Row className="text-primary fs-10 fw-bold pt-1" noGutters>
        <Col className="min-w-29p max-w-29p ps-4 me-1">SUPPLIER</Col>

        <Col className="min-w-7p max-w-7p">PO DATE</Col>

        <Col className="min-w-12p max-w-12p text-end me-2">QUOTED USD</Col>

        <Col className="min-w-12p max-w-12p text-end me-2">PROPOSED USD</Col>

        <Col className="min-w-12p max-w-12p text-end me-6">APPROVED USD</Col>

        <Col className="sm-w-10p mdlaptop-w-14p hd-w-15p lghd-w-18p">DELIVERY REMARKS</Col>
      </Row>

      <div className="pb-6">
        {suppliers?.map(supplier => (
          <Supplier key={supplier.id} data={supplier} />
        ))}
      </div>
    </>
  );
};

export default Suppliers;
