import { useQuery } from '@tanstack/react-query';
import { getCaptainReportsAlarmsDue, GetCaptainReportsAlarmsDueParams } from './api';
import { CAPTAIN_REPORT_ALARMS_DUE } from './query-keys';

export const useGetCaptainReportsAlarmsDue = (params: GetCaptainReportsAlarmsDueParams) => {
  return useQuery({
    queryKey: [CAPTAIN_REPORT_ALARMS_DUE, params],
    queryFn: () => getCaptainReportsAlarmsDue(params),
    enabled: !!params.vessel_id,
    retry: false
  });
};
