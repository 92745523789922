import PropTypes from 'prop-types';
import Editor from 'common/components/form/inputs/Editor';
import UploadFiles from 'common/components/form/inputs/upload-files';
import Logs from './Logs';
import LinkEntities from 'common/components/link-entities';
import { FormField } from 'common/entities/form/FormTypes';
import { useSelector } from 'react-redux';
import moment from 'moment';

const Body = ({
  onTogglePurchasingCaseDrawer,
  changeField,
  selectField,
  active,
  vessel,
  ...fields
}) => {
  const isOnBoard = useSelector(state => state.isOnBoard);
  const isViewOnly = isOnBoard && active;

  return (
    <div className="h-100p py-3 ps-4">
      {isOnBoard ? null : (
        <LinkEntities
          withTabs
          vessel={vessel}
          jobsState={{
            linkedJobs: fields.jobs.value,
            addLinkedJob: job => selectField('jobs')([...(fields.jobs.value || []), job]),
            removeLinkedJob: index =>
              selectField('jobs')(
                index >= 0 ? fields.jobs.value.filter((_, i) => i !== index) : []
              ),
            prefillState: {
              title: fields.name.value || null,
              description: fields.notes.value || '',
              due_date: fields.due_date.value ? moment(fields.due_date.value) : '',
              main_department: fields.department_id.value || null
            }
          }}
          formsState={{
            linkedForms: fields.form_submissions.value,
            addLinkedForm: form =>
              selectField('form_submissions')([...(fields.form_submissions.value || []), form]),
            removeLinkedForm: index =>
              selectField('form_submissions')(
                index >= 0 ? fields.form_submissions.value.filter((_, i) => i !== index) : []
              )
          }}
          purchasingCasesState={{
            onTogglePurchasingCaseDrawer,
            linkedPurchasingCases: fields.purchasing_requisitions.value,
            addLinkedPurchasingCase: requisition =>
              selectField('purchasing_requisitions')([
                ...(fields.purchasing_requisitions.value || []),
                requisition
              ]),
            removeLinkedPurchasingCase: index =>
              selectField('purchasing_requisitions')(
                index >= 0 ? fields.purchasing_requisitions.value.filter((_, i) => i !== index) : []
              )
          }}
        />
      )}

      <Editor
        label="Notes"
        viewOnly={isViewOnly}
        disabled={isViewOnly}
        placeholder={isViewOnly ? '' : `Add some text`}
        onChange={selectField('notes')}
        {...fields.notes}
      />

      <UploadFiles
        label="Attachments"
        group="findings"
        viewOnly={isViewOnly}
        files={fields.attachments.value}
        onChange={files => selectField('attachments')(files)}
        uploadText="Upload file(s)"
        size="lg"
      />

      <Logs data={active} />
    </div>
  );
};

Body.propTypes = {
  onTogglePurchasingCaseDrawer: PropTypes.func,
  changeField: PropTypes.func,
  selectField: PropTypes.func,
  active: PropTypes.object,
  fields: PropTypes.shape({
    notes: FormField,
    attachments: FormField
  })
};

export default Body;
