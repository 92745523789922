import VesselSelector from 'common/components/selectors/VesselSelector';
import FormObligationTriggerSelector from 'common/components/selectors/FormObligationTriggerSelector';
import CrewSelector from 'common/components/selectors/CrewSelector';
import FormsSelector from 'common/components/selectors/FormsSelector';
import DepartmentSelector from 'common/components/selectors/departments/DepartmentSelector';
import DepartmentRolesSelector from 'common/components/selectors/DepartmentRolesSelector';
import CrewRankSelector from 'common/components/selectors/CrewRankSelector';
import { EventTemplateListingOptionType } from '@/common/types/enums.ts';
import formsObligationsIcon from '@/common/assets/svg/common/forms-obligations.svg';
import TopFilters from './TopFilters';
import ListSelect from '../../selectors/ListSelect';
import EventSelector from '../../selectors/EventSelector';

const getColumns = (isInCompletedTab: boolean) => [
  {
    header: 'Form',
    key: 'form',
    type: 'collection',
    sticky: true,
    left: 0,
    minWidth: 370,
    maxWidth: 370,
    sort: true,
    canFilter: true,
    component: FormsSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Trigger',
    key: 'trigger',
    sticky: true,
    left: 370,
    minWidth: 370,
    maxWidth: 370,
    sort: true,
    canFilter: false
  },
  {
    header: 'Trigger',
    key: 'trigger_type_id',
    type: 'collection',
    component: FormObligationTriggerSelector,
    hiddenOnBoard: true,
    componentRest: { isMulti: true },
    sort: false,
    hidden: true,
    canFilter: true
  },
  {
    header: 'For The Event Templates',
    key: 'event_template',
    type: 'collection',
    minWidth: 250,
    maxWidth: 250,
    sort: false,
    canFilter: true,
    component: ListSelect,
    componentRest: {
      isMulti: true,
      list: 'event-templates',
      getOptionLabel: (opt: EventTemplateListingOptionType) => opt.template_name
    }
  },
  {
    header: 'Event Date',
    key: 'event_date',
    data_key: 'trigger_entity.started_at',
    type: 'date',
    minWidth: 120,
    maxWidth: 120,
    sort: true,
    canFilter: true
  },
  {
    header: 'DOS',
    key: 'date_of_switch',
    type: 'date',
    minWidth: 105,
    maxWidth: 105,
    sort: true,
    canFilter: false
  },
  {
    header: 'Responsible',
    key: 'responsible',
    type: 'collection',
    minWidth: 205,
    maxWidth: 205,
    sort: true,
    canFilter: false
  },
  {
    header: 'For the Rank',
    key: 'crew_rank',
    sort: true,
    minWidth: 160,
    maxWidth: 160,
    canFilter: false
  },
  {
    header: 'For the Rank',
    key: 'crew_rank_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: CrewRankSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Seaman',
    key: 'crew_member',
    sort: true,
    minWidth: 225,
    maxWidth: 225,
    canFilter: false
  },
  {
    header: 'Seaman',
    key: 'crew_member_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: CrewSelector,
    componentRest: { isMulti: true }
  },
  {
    header: 'Responsible Department',
    key: 'responsible_department_id',
    hiddenOnBoard: true,
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: DepartmentSelector
  },
  {
    header: 'Responsible Role',
    key: 'responsible_role_id',
    type: 'collection',
    hiddenOnBoard: true,
    sort: false,
    hidden: true,
    canFilter: true,
    component: DepartmentRolesSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Responsible Crew Rank',
    key: 'responsible_crew_rank_id',
    type: 'collection',
    sort: false,
    hidden: true,
    canFilter: true,
    component: CrewRankSelector,
    componentRest: {
      isMulti: true
    }
  },
  {
    header: 'Vessel',
    key: 'vessel',
    data_key: 'vessel.name',
    type: 'collection',
    sort: true,
    canFilter: true,
    component: VesselSelector,
    componentRest: { isMulti: true },
    minWidth: 165,
    maxWidth: 165
  },
  {
    header: 'Days Left',
    key: 'days_left',
    type: 'number',
    sort: true,
    canFilter: false,
    minWidth: 125,
    maxWidth: 125,
    hidden: isInCompletedTab,
    className: 'd-flex align-items-end justify-content-center'
  },
  {
    header: 'Ignore',
    key: 'ignore',
    type: 'boolean',
    sort: false,
    canFilter: false,
    minWidth: 200,
    maxWidth: 200,
    hidden: isInCompletedTab,
    hiddenOnBoard: true,
    className: 'd-flex align-items-center justify-content-center'
  },
  {
    header: 'Timeframe Opened',
    key: 'is_visible',
    type: 'boolean',
    sort: false,
    hidden: true,
    canFilter: true,
    options: [
      { label: 'Yes', value: 'true' },
      { label: 'No', value: 'false' }
    ]
  },
  {
    header: 'Where',
    key: 'is_for_vessel',
    type: 'boolean',
    hidden: true,
    sort: false,
    canFilter: true,
    options: [
      { value: 'true', label: 'Onboard' },
      { value: 'false', label: 'Office' }
    ]
  },
  {
    header: 'Form Date',
    key: 'form_date',
    data_key: 'form_submission.submission_date',
    type: 'date',
    minWidth: 120,
    maxWidth: 120,
    hidden: !isInCompletedTab,
    sort: true,
    canFilter: false
  },
  {
    header: 'For The Event',
    key: 'event_id',
    type: 'collection',
    hidden: true,
    sort: false,
    canFilter: true,
    component: EventSelector,
    componentRest: { isMulti: true, listParams: { modules: ['forms'] } }
  },
  {
    header: 'Actions',
    key: 'actions',
    field: 'actions'
  }
];

export const getTopFilters = () => [
  {
    name: 'status',
    operation: '=',
    value: 'pending'
  }
];

const config = {
  getColumns,
  getTopFilters,
  isNewTable: false,
  tableStyle: 'default',
  topFiltersComponent: TopFilters,
  icon: formsObligationsIcon,
  hideTableReorder: false,
  isModular: false,
  hideTableFilters: false,
  pageTitleComponent: (
    <div className="d-flex align-items-center">
      Forms <div className="fw-bold ms-1">/ Obligations</div>
    </div>
  ),
  name: 'Form Obligations',
  entity: 'form-obligations'
};

export default config;
